
import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from './Config';
import GLightbox from 'glightbox';
import 'glightbox/dist/css/glightbox.css';
import Loader from './loader';
import { Helmet } from 'react-helmet-async';

const Product_Detail = () => {
    // const { pid } = useParams(); // Get the "pid" parameter from the URL
    const currentUrl = window.location.href;
    console.log(currentUrl)
    const [isLoading, setIsLoading] = useState(true);


    const valid = currentUrl.split("/");
    // console.log(valid)

    const v1 = valid[3].split("?")

    const pid = v1[1]

    const np = pid.split("_")
    const msg = np[1]
    const ppii = np[0]
    const dataa = msg.split("-");
    // console.log(dataa) 
    const [productData, setProductData] = useState(null);
    const [activeTab, setActiveTab] = useState('description'); // Initialize the active tab

    useEffect(() => {
        // Fetch product details using the "pid" parameter
        axios.post(BASE_URL + "Product_Details.php", { pid: ppii })
            .then(response => {
                console.log(response)
                setProductData(response.data.result[0]);
                setIsLoading(false)

            })
            .catch(error => {
                console.error(error);
            });
        // Initialize GLightbox
        const lightbox = GLightbox();

        // Clean up GLightbox when the component unmounts
        return () => {
            lightbox.destroy();
        };
    }, [pid]);

    // console.log(productData)

    if (!productData) {
        // Loading state or error handling can be added here
        return <div> <Loader /> </div>;
    }


    return (
        <div>
            <Helmet>
                {productData ? (<><title>{productData.productName} ({productData.modelNo}) - {productData.cateName} , {productData.compName} | RealWeight</title>)
                    <meta name="description" content="Realweight, established in 2015, is a trusted manufacturer and supplier of high-quality electronic weighing scales and scale spares. Our product range includes electronic table top scales, digital price computing scales, precision pocket scales, and more. We are committed to delivering durable, precise, and affordable weighing scale solutions. Choose Realweight for your weighing needs." />

                    <meta property="og:title" content="RealWeight: Precision Weighing Scales Manufacturer & Supplier" />
                    <meta property="og:site_name" content="RealWeight: Precision Weighing Scales Manufacturer & Supplier" />
                    <meta property="og:url" content="https://realweight.in/" />
                    <meta property="og:image" content={`https://realweight.in/assets/img/order.png`} />
                    <meta property="og:image:url" content={`https://realweight.in/assets/img/order.png`} />
                    <meta property="og:image:width" content="500" />
                    <meta property="og:image:height" content="500" />
                    <meta property="og:description"
                        content="Realweight, established in 2015, is a trusted manufacturer and supplier of high-quality electronic weighing scales and scale spares. Our product range includes electronic table top scales, digital price computing scales, precision pocket scales, and more. We are committed to delivering durable, precise, and affordable weighing scale solutions. Choose Realweight for your weighing needs." />
                </>) : ""}
            </Helmet>

            <section className="breadcrumb__section breadcrumb__bg">
                <div className="container">
                    <div className="row row-cols-1">

                        <div className="col">
                            <div className="breadcrumb__content">
                                {productData ? (<>
                                    <h1 className="breadcrumb__content--title mb-10">{productData.productName}</h1>
                                    <ul className="breadcrumb__content--menu d-flex">
                                        <li className="breadcrumb__content--menu__items"><Link to="/">Home</Link></li>
                                        <li className="breadcrumb__content--menu__items"><Link to="/Category">{productData.compName}</Link></li>
                                        <li className="breadcrumb__content--menu__items">{productData.cateName}</li>
                                        <li className="breadcrumb__content--menu__items"><span className="text__secondary">{productData.productName}</span></li>
                                    </ul></>

                                ) : ""}
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {isLoading ? (
                <Loader />
            ) : (<>

                <section className="product__details--section section--padding">
                    <div className="container">
                        <div className="row row-cols-lg-2 row-cols-md-2 row-cols-1">
                            <div className="col">
                                <div className="product__details--media d-flex">
                                    {/* Product media */}
                                    <div className="product__media--right">
                                        <div className="product__media--preview  swiper">
                                            <div className="swiper-wrapper">
                                                <div className="swiper-slide">
                                                    <div className="product__media--preview__items">
                                                        <Link className="product__media--preview__items--link glightbox" data-gallery="product-media-preview" to={`/assets/img/products_details/${productData.img}`}>
                                                        </Link>
                                                        <button style={{ border: "none", background: "none" }} className="product__media--preview__items--link"
                                                            id="openLightboxButton"
                                                            onClick={() => {
                                                                const image = {
                                                                    href: '/assets/img/product_details/realweight_27.jpg', // Replace with the path to your image
                                                                    title: 'Image Title',
                                                                };
                                                                GLightbox([image]).open();
                                                            }}
                                                        >
                                                            <img className="product__media--preview__items--img" src={`/assets/img/products/${productData.img}`} alt="product-media-img" />

                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                {/* Product info */}
                                <div className="product__details--info">
                                    <form action="#">
                                        <h3 className="product__details--info__title mb-15">{productData.productName}</h3>
                                        <div className="product__tab--conten">
                                            <div className="product__tab--content__step mb-30">
                                                <ul className="additional__info_list">
                                                    <li className="additional__info_list--item">
                                                        <span className="info__list--item-head"><strong>Model No :</strong></span>
                                                        <span className="info__list--item-content">{productData.modelNo}</span>
                                                    </li>
                                                    <li className="additional__info_list--item">
                                                        <span className="info__list--item-head"><strong>Pan Size :</strong></span>
                                                        <span className="info__list--item-content">{productData.pan}</span>
                                                    </li>
                                                    <li className="additional__info_list--item">
                                                        <span className="info__list--item-head"><strong>Capacity :</strong></span>
                                                        <span className="info__list--item-content">{productData.capacity}</span>
                                                    </li>
                                                    <li className="additional__info_list--item">
                                                        <span className="info__list--item-head"><strong>Accuracy :</strong></span>
                                                        <span className="info__list--item-content">{productData.accuracy}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="product__variant">
                                            <div className="product__variant--list mb-15">
                                                <a style={{fontSize:"20px"}}
                                                    href={`https://wa.me/917001507342?text=I%20have%20requirement%20of%20${dataa[0]},%20${dataa[1]},%20${dataa[2]}%20${dataa[3]}%0A%0A${encodeURIComponent(currentUrl)}`}
                                                    target='_blank'
                                                    className="card__btn primary__btn"
                                                >
                                                    <img src="/assets/img/whatsapp.png" alt="wp" style={{ paddingTop: "4px", marginRight: "8px",height:"19px" }} />Get Price
                                                </a>
                                                {/* <a
                                                    href={`https://wa.me/917001507342?text=I%20have%20requirnment%20of%20${dataa[0]},%20${dataa[1]},%20${dataa[2]}%20${dataa[3]}%0A%0A${currentUrl}`}
                                                    target='_blank'
                                                    className="card__btn primary__btn"
                                                >
                                                    Get Price &nbsp;&nbsp;&nbsp;&nbsp;<img src="/assets/img/whatsapp.png" alt="wp" style={{ marginTop: "10px" }} />
                                                </a> */}


                                                {/* <a href={`https://wa.me/917001507342?text=I%20have%20requirnment%20of%20Weigh%20Scale%0A%0Ahttps://realweight.in/product_details?15_(RWCH)-CHIKEN%20SCALE-Bench%20Type%20Scale-Real%20Weight`} target="_blank">Click here to send a WhatsApp message</a> */}

                                            </div>
                                            {/* <div className="product__variant--list mb-15">
                                            <div className="product__details--info__meta">
                                                <p className="product__details--info__meta--list"><strong>Categories:</strong>  <span>Grocery / Kirana Scale, Waterproof Scale, Weighing Scales</span> </p>
                                                <p className="product__details--info__meta--list"><strong>Tags:</strong>  <span>20 kg, Realweight, Weighing Scales</span> </p>
                                            </div>
                                        </div> */}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>)}

            {/* Product details tab section */}
            {/* <section className="product__details--tab__section section--padding">
                <div className="container">
                    <div className="row row-cols-1">
                        <div className="col">
                            <ul className="product__tab--one product__details--tab d-flex mb-30">
                                <li
                                    className={`product__details--tab__list ${activeTab === 'description' ? 'active' : ''}`}
                                    onClick={() => setActiveTab('description')}
                                >
                                    Features
                                </li>
                                <li
                                    className={`product__details--tab__list ${activeTab === 'reviews' ? 'active' : ''}`}
                                    onClick={() => setActiveTab('reviews')}
                                >
                                    Application
                                </li>
                                <li
                                    className={`product__details--tab__list ${activeTab === 'information' ? 'active' : ''}`}
                                    onClick={() => setActiveTab('information')}
                                >
                                    Additional Info
                                </li>
                            </ul>
                            <div className="product__details--tab__inner border-radius-10">
                                <div className="tab_content">
                                    <div
                                        id="description"
                                        className={`tab_pane ${activeTab === 'description' ? 'active show' : ''}`}
                                    >
                                         <div class="product__tab--content">
                                        <div class="product__tab--content__step mb-30">
                                            <h2 class="product__tab--content__title h4 mb-10"></h2>
                                            {/* <!--<p class="product__tab--content__desc">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam provident sequi, nemo sapiente culpa nostrum rem eum perferendis quibusdam, magnam a vitae corporis! Magnam enim modi, illo harum suscipit tempore aut dolore doloribus deserunt voluptatum illum, est porro? Ducimus dolore accusamus impedit ipsum maiores, ea iusto temporibus numquam eaque mollitia fugiat laborum dolor tempora eligendi voluptatem quis necessitatibus nam ab?</p>--> 
                                        </div>
                                        <div class="image__with--text__percent product__tab--percent__style">
                                        <ul>
                                            <p class="product__details--info__meta--list"><span>- Precision Load Cells</span></p>
                                            <p class="product__details--info__meta--list"><span>- Advanced Micro Processor Technology</span></p>
                                            <p class="product__details--info__meta--list">- Full Waterproof Body and Loadcells</p>
                                            <p class="product__details--info__meta--list">- Auto Tracking Zero</p>
                                            <p class="product__details--info__meta--list">- Battery Back-up up to 25 hours</p>
                                            <p class="product__details--info__meta--list">- Auto Calibration facility</p>
                                            <p class="product__details--info__meta--list">- Optional printer facility</p>
                                        </ul>
                                        </div>
                          
                                    </div> 
                                    </div>
                                    <div
                                        id="reviews"
                                        className={`tab_pane ${activeTab === 'reviews' ? 'active show' : ''}`}
                                    >
                                        <div class="image__with--text__percent product__tab--percent__style">
                                        <ul>
                                            <p class="product__details--info__meta--list"><span>- Poultry</span></p>
                                            <p class="product__details--info__meta--list"><span>- Meat Processing</span></p>
                                            <p class="product__details--info__meta--list">- Fishing</p>
                                            <p class="product__details--info__meta--list">- Packaging Industry</p>
                                            <p class="product__details--info__meta--list">- Sampling</p>
                                            <p class="product__details--info__meta--list">- Agriculture</p>
                                        </ul>
                                    </div>
                                    </div>
                                    <div
                                        id="information"
                                        className={`tab_pane ${activeTab === 'information' ? 'active show' : ''}`}
                                    >
                                         <div class="product__tab--conten">
                                        <div class="product__tab--content__step mb-30">
                                            <ul class="additional__info_list">
                                                <p class="additional__info_list--item">
                                                    <span class="info__list--item-head"><strong>Weight :</strong></span>
                                                    <span class="info__list--item-content">2.78 kg</span>
                                                </p>
                                                <p class="additional__info_list--item">
                                                    <span class="info__list--item-head"><strong>Dimensions :</strong></span>
                                                    <span  class="info__list--item-content">26 × 24 × 14 cm</span>
                                                </p>
                                               
                                            </ul>
                                        </div>
                                    </div> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
        </div >
    );
};

export default Product_Detail;
