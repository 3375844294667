import React from "react";
import './index.css';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Header from "./pages/Header";
import Footer from "./pages/Footer";
import Category from "./pages/Category";
import About from "./pages/About";
import User from "./pages/User";
import Install from "./pages/Install";
import AMC from "./pages/AMC";
import Repair from "./pages/Rapair";
import Contact from "./pages/Contact";
import Product from "./pages/Product";
import Home from "./pages/Home";
import Product_Detail from "./pages/Product_Details";
import ScrollToTop from "./pages/ScrollToTop";
import { HelmetProvider } from 'react-helmet-async';

function App() {
  return (

    <HelmetProvider>
      <Router>
        <div>
          <Header />
          <ScrollToTop />
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/About" component={About} />
            <Route path="/Install" component={Install} />
            <Route path="/AMC" component={AMC} />
            <Route path="/Repair" component={Repair} />
            <Route path="/Contact" component={Contact} />
            <Route path="/Category" component={Category} />
            <Route path="/User" component={User} />
            <Route path="/Product" component={Product} />
            {/* <Route path="/Product" component={Product} /> */}
            <Route path="/product_details" component={Product_Detail} />
          </Switch>
          <Footer />
        </div>
      </Router>
    </HelmetProvider>
  );
}

export default App;
