


import React from 'react'
import { Helmet } from 'react-helmet-async'
const About = () => {
    return (
        <div>
            <Helmet>
                <title>About Us | RealWeight</title>
                <meta name="description" content="Realweight, established in 2015, is a trusted manufacturer and supplier of high-quality electronic weighing scales and scale spares. Our product range includes electronic table top scales, digital price computing scales, precision pocket scales, and more. We are committed to delivering durable, precise, and affordable weighing scale solutions. Choose Realweight for your weighing needs." />

                <meta property="og:title" content="RealWeight: Precision Weighing Scales Manufacturer & Supplier" />
                <meta property="og:site_name" content="RealWeight: Precision Weighing Scales Manufacturer & Supplier" />
                <meta property="og:url" content="https://realweight.in/" />
                <meta property="og:image" content="https://realweight.in/assets/img/logo.png" />
                <meta property="og:image:url" content="https://realweight.in/assets/img/logo.png" />
                <meta property="og:image:width" content="500" />
                <meta property="og:image:height" content="500" />
                <meta property="og:description"
                    content="Realweight, established in 2015, is a trusted manufacturer and supplier of high-quality electronic weighing scales and scale spares. Our product range includes electronic table top scales, digital price computing scales, precision pocket scales, and more. We are committed to delivering durable, precise, and affordable weighing scale solutions. Choose Realweight for your weighing needs." />

            </Helmet>
            <main class="main__content_wrapper">

                {/* <!-- Start breadcrumb section --> */}
                <section class="breadcrumb__section breadcrumb__bg">
                    <div class="container">
                        <div class="row row-cols-1">
                            <div class="col">
                                <div class="breadcrumb__content">
                                    <h1 class="breadcrumb__content--title mb-10">About Us</h1>
                                    <ul class="breadcrumb__content--menu d-flex">
                                        <li class="breadcrumb__content--menu__items"><a href="/">Home</a></li>
                                        <li class="breadcrumb__content--menu__items"><span class="text__secondary">About Us</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- End breadcrumb section -->

       <!-- Start image with text section --> */}
                <section class="image__with--text__section section--padding">
                    <div class="container">
                        <div class="row row-cols-md-2 row-cols-1  align-items-center">
                            <div class="col">
                                <div class="image__with--text__thumbnail">
                                    <img class="display-block" src="assets/img/weight/weight.png" alt="drone-image" />
                                </div>
                            </div>
                            <div class="col">
                                <div class="image__with--text__content">
                                    <h2 class="image__with--text__title mb-18">About Realweight</h2>
                                    <p class="image__with--text__desc mb-25" style={{ textAlign: "justify;" }}><b>Realweight</b>, established in <b>2015</b>, is a leading manufacturer, exporter, supplier, trader, and wholesaler of Electronic Weighing Scales and Electronic Scales Spares. Our range includes Electronic Table Top Scales, Digital Price Computing Scales, Digital Pocket Precision Scales, Electronic Personal Weighing Scales, Regular Platform Scale Load Cell, and Digital Crane Weighing Scales. Crafted with precision and sourced from certified vendors, our products ensure accuracy and reliability. </p>
                                    <p class="image__with--text__desc mb-25" style={{ textAlign: "justify;" }}>With a customer-centric approach, we prioritize satisfaction and foster long-lasting relationships. Our dedicated customer service team is always available to address queries and concerns. Offering direct sales, we simplify the buying process and ensure a seamless experience. <b>Realweight</b> is committed to delivering superior products that combine durability, precision, and affordability. </p>
                                    <p class="image__with--text__desc mb-25" style={{ textAlign: "justify;" }}>Choose us as your trusted partner for weighing scale solutions. Experience our unwavering commitment to quality, reliability, and customer satisfaction. Thank you for considering <b>Realweight</b> for your weighing scale needs.</p>

                                </div>
                            </div>
                        </div>
                    </div>
                    <img class="image__with--text__position--shape display-block" src="assets/img/other/image-with-text-section-shape.webp" alt="shape img" />
                </section>


                <section class="about__section section--padding border-bottom">
                    <div class="container">
                        <div class="row row-cols-lg-2 row-cols-md-2 row-cols-1 align-items-center">
                            <div class="col">
                                <div class="about__content">
                                    <h2 class="about__content--title mb-18">Our Service</h2>
                                    <div class="about__content--step mb-25">
                                        <p class="about__content--desc mb-20" style={{ textAlign: "justify;" }}>At <b>Realweight</b>, we pride ourselves on providing a comprehensive range of services. Our offerings are designed with adherence to industry norms and cutting-edge technology. Stringent quality control measures ensure that our scales are fault-free and meet customer requirements. Prior to delivery, our products undergo thorough examination on multiple quality parameters to guarantee superior performance and reliability.</p>
                                        <ul class="mb-20">
                                            <li class="about__content--desc__list" style={{ textAlign: "justify;" }}>We understand the significance of delivering flawless products to our customers. By upholding rigorous quality control practices, <b>Realweight</b> ensures that our scales surpass expectations and are free from manufacturing faults.</li>
                                            <li class="about__content--desc__list" style={{ textAlign: "justify;" }}>Our unwavering commitment to quality sets us apart. We continuously strive to enhance our services, ensuring customer satisfaction. Choose <b>Realweight</b> with confidence, knowing that you will receive exceptional, thoroughly tested scales.</li>
                                        </ul>
                                        <p class="about__content--desc style2" style={{ textAlign: "justify;" }}>Experience the <b>Realweight</b> difference for your weighing scale needs. Thank you for considering us.</p>
                                    </div>
                                    <div class="about__content--author d-flex align-items-center mb-50">

                                    </div>
                                    <a class="about__content--btn primary__btn" href="/Contact">Contact Us</a>
                                </div>
                            </div>
                            <div class="col">
                                <div class="about__thumbnail">
                                    <img class="display-block" src="assets/img/weight/wbg.png" alt="about-thumb" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- End about section --> */}





            </main>
        </div>
    )
}

export default About
