




import React from 'react'
import { Link } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import { BASE_URL } from './Config';
import { Helmet } from 'react-helmet-async';

const Install = () => {


    const [verified, setVerified] = useState('');
    const [ercapacha, setErcapacha] = useState('')
    const [status, setStatus] = useState('')
    const [visible, setIsVisible] = useState(true);
    const captch = useRef(null);



    const key = '6LeshDQoAAAAAFP5APIa-qwVvFL6UgODyNCICcO3';

    function onChange() {
        setVerified(true);
    }




    const initalState = {
        names: "",
        email: "",
        message: "",
        lname: "",
        phone: ""
    };

    const [state, setState] = useState(initalState);
    const { names, lname, phone, email, message } = state;

    const changeHandler = (e) => {
        const { name, value } = e.target;
        setState({ ...state, [name]: value });
    };



    const handleSubmit = (e) => {
        console.log("click")
        e.preventDefault();

        if (verified === true) {
            axios
                .post(BASE_URL + "installtion.php", {
                    names,
                    email,
                    message,
                    phone
                })
                .then(function (data) {
                    console.log(data.data.message);
                    if (data.data.message === "Message Sent") {
                        captch.current.reset()
                        setState({ names: "", email: "", message: "", lname: "", phone: "" });
                        setStatus("Message sent successfully. We will connect you soon");
                        setErcapacha("")
                        setIsVisible(false);

                    }else{
                        setErcapacha("Message not sent.Try again");
                        setStatus("")
                        setIsVisible(true);
                    }
                })
        } else {
            setErcapacha("Please Verify CAPTCHA")
            setIsVisible(true);
            setTimeout(function () {
                setIsVisible(false);
            }, 3000);
            console.log("fail");

        }

    };
    return (
        <div>
              <Helmet>
                <title>Installation Consultancy | RealWeight</title>
                <meta name="description" content="Realweight, established in 2015, is a trusted manufacturer and supplier of high-quality electronic weighing scales and scale spares. Our product range includes electronic table top scales, digital price computing scales, precision pocket scales, and more. We are committed to delivering durable, precise, and affordable weighing scale solutions. Choose Realweight for your weighing needs." />

                <meta property="og:title" content="RealWeight: Precision Weighing Scales Manufacturer & Supplier" />
                <meta property="og:site_name" content="RealWeight: Precision Weighing Scales Manufacturer & Supplier" />
                <meta property="og:url" content="https://realweight.in/" />
                <meta property="og:image" content="https://realweight.in/assets/img/logo.png" />
                <meta property="og:image:url" content="https://realweight.in/assets/img/logo.png" />
                <meta property="og:image:width" content="500" />
                <meta property="og:image:height" content="500" />
                <meta property="og:description"
                    content="Realweight, established in 2015, is a trusted manufacturer and supplier of high-quality electronic weighing scales and scale spares. Our product range includes electronic table top scales, digital price computing scales, precision pocket scales, and more. We are committed to delivering durable, precise, and affordable weighing scale solutions. Choose Realweight for your weighing needs." />

            </Helmet>
            <main class="main__content_wrapper">

                {/* <!-- Start breadcrumb section --> */}
                <section class="breadcrumb__section breadcrumb__bg">
                    <div class="container">
                        <div class="row row-cols-1">
                            <div class="col">
                                <div class="breadcrumb__content">
                                    <h1 class="breadcrumb__content--title mb-10">Installation Consultancy</h1>
                                    <ul class="breadcrumb__content--menu d-flex">
                                        <li class="breadcrumb__content--menu__items"><Link to="/">Home</Link></li>
                                        <li class="breadcrumb__content--menu__items"><span class="text__secondary">Installation Consultancy</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- End breadcrumb section -->

<!-- Start Installation Consultancy section --> */}
                <div class="privacy__policy--section section--padding">
                    <div class="container">
                        <div class="row">
                            <div class="col-12">
                                <div class="privacy__policy--content">
                                    {/* <!--<h2 class="privacy__policy--content__title">Who we are</h2>--> */}
                                    <p class="privacy__policy--content__desc">Installation of any scales is perhaps one of the most important concept in any weighing Scale System. Only with the help of qualified technicians can a weighing scale system be properly installed and maintained. Moreover, Trained and qualified technicians will add more than 100% to the scale’s life and the efficiency of such systems will always be extremely high.</p>
                                    <p class="privacy__policy--content__desc">All our trained technicians have more than 10 years of industry experience making them one of the most qualified people in the industry for installation consultancy. If you opt for scale systems designed by us you are eligible for attractive discounts.</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- End Installation Consultancy section --> */}

                <section class="contact__section section--padding">
                    <div class="container">
                        <div class="section__heading text-center mb-40">
                            <h2 class="section__heading--maintitle text__secondary mb-10">Contact us</h2>
                            <p class="section__heading--desc">Share your problem with us.</p>
                        </div>
                        <div class="main__contact--area">
                            <div class="row align-items-center row-md-reverse">
                                <div class="col-lg-12">
                                    <div class="contact__form">
                                        <form class="contact__form--inner" onSubmit={handleSubmit}>
                                            <div class="row">
                                                <div class="col-lg-12 col-md-6">
                                                    <div class="contact__form--list mb-20">
                                                        <label class="contact__form--label" for="input1">Name <span class="contact__form--label__star">*</span></label>
                                                        <input class="contact__form--input" name="names" onChange={changeHandler} value={state.names} id="input1" placeholder="Your First Name" type="text" />
                                                    </div>
                                                </div>

                                                <div class="col-lg-12 col-md-6">
                                                    <div class="contact__form--list mb-20">
                                                        <label class="contact__form--label" for="input3">Phone Number <span class="contact__form--label__star">*</span></label>
                                                        <input class="contact__form--input" name="phone" id="input3" onChange={changeHandler} value={state.phone} placeholder="Phone number" type="text" />
                                                    </div>
                                                </div>

                                                <div class="col-lg-12 col-md-6">
                                                    <div class="contact__form--list mb-20">
                                                        <label class="contact__form--label" for="input3">Email <span class="contact__form--label__star">*</span></label>
                                                        <input class="contact__form--input" name="email" id="input3" onChange={changeHandler} value={state.email} placeholder="Email" type="email" />
                                                    </div>
                                                </div>

                                                <div class="col-12">
                                                    <div class="contact__form--list mb-20">
                                                        <label class="contact__form--label" for="input5">Comments or Message <span class="contact__form--label__star">*</span></label>
                                                        <textarea class="contact__form--textarea" name="message" onChange={changeHandler} value={state.message} id="input5" placeholder="Write Your Message"></textarea>
                                                    </div>
                                                </div>


                                            </div>
                                            <div class="account__login--remember position__relative mb-15">
                                                <ReCAPTCHA
                                                    ref={captch}
                                                    sitekey={key}
                                                    onChange={onChange}
                                                />


                                            </div>
                                            <button class="contact__form--btn primary__btn" type="submit" name='submit' >Submit Now</button>
                                            {<p style={{ color: 'red' }}>{ercapacha}</p>}
                                            {<p style={{ color: 'green' }}>{status}</p>}
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>



            </main>
        </div>
    )
}

export default Install;