
import React from 'react'
import { Link } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import { BASE_URL } from './Config';
import { Helmet } from 'react-helmet-async';

const Repair = () => {


    const [verified, setVerified] = useState('');
    const [ercapacha, setErcapacha] = useState('')
    const [status, setStatus] = useState('')
    const [visible, setIsVisible] = useState(true);
    const captch = useRef(null);



    const key = '6LeshDQoAAAAAFP5APIa-qwVvFL6UgODyNCICcO3';

    function onChange() {
        setVerified(true);
    }

    const initalState = {
        names: "",
        email: "",
        message: "",
        lname: "",
        phone: "",
        problem: "",
        brand: ""
    };

    const [state, setState] = useState(initalState);
    const { names, lname, phone, email, message, problem, brand } = state;

    const changeHandler = (e) => {
        const { name, value } = e.target;
        setState({ ...state, [name]: value });
    };



    const handleSubmit = (e) => {
        console.log("click")
        e.preventDefault();

        if (verified === true) {
            axios
                .post(BASE_URL + "repair.php", {
                    names,
                    email,
                    message,
                    phone,
                    problem,
                    brand
                })
                .then(function (data) {
                    console.log(data.data.message);
                    if (data.data.message === "Message Sent") {
                        captch.current.reset()
                        setState({ names: "", email: "", message: "", lname: "", phone: "", problem: "", brand: "" });
                        setStatus("Message sent successfully. We will connect you soon")
                        setIsVisible(false);
                        setErcapacha("")

                    } else {
                        setErcapacha("Message not sent.Try again");
                        setStatus("")
                        setIsVisible(true);
                    }
                })
        } else {
            setErcapacha("Please Verify CAPTCHA")
            setIsVisible(true);
            setTimeout(function () {
                setIsVisible(false);
            }, 3000);
            console.log("fail");

        }

    };
    return (
        <div>

            <Helmet>
                <title>Repair Request | RealWeight</title>
                <meta name="description" content="Realweight, established in 2015, is a trusted manufacturer and supplier of high-quality electronic weighing scales and scale spares. Our product range includes electronic table top scales, digital price computing scales, precision pocket scales, and more. We are committed to delivering durable, precise, and affordable weighing scale solutions. Choose Realweight for your weighing needs." />

                <meta property="og:title" content="RealWeight: Precision Weighing Scales Manufacturer & Supplier" />
                <meta property="og:site_name" content="RealWeight: Precision Weighing Scales Manufacturer & Supplier" />
                <meta property="og:url" content="https://realweight.in/" />
                <meta property="og:image" content="https://realweight.in/assets/img/logo.png" />
                <meta property="og:image:url" content="https://realweight.in/assets/img/logo.png" />
                <meta property="og:image:width" content="500" />
                <meta property="og:image:height" content="500" />
                <meta property="og:description"
                    content="Realweight, established in 2015, is a trusted manufacturer and supplier of high-quality electronic weighing scales and scale spares. Our product range includes electronic table top scales, digital price computing scales, precision pocket scales, and more. We are committed to delivering durable, precise, and affordable weighing scale solutions. Choose Realweight for your weighing needs." />

            </Helmet>
            <main class="main__content_wrapper">



                {/* <!-- Start breadcrumb section --> */}
                <section class="breadcrumb__section breadcrumb__bg">
                    <div class="container">
                        <div class="row row-cols-1">
                            <div class="col">
                                <div class="breadcrumb__content">
                                    <h1 class="breadcrumb__content--title mb-10">Repair Request</h1>
                                    <ul class="breadcrumb__content--menu d-flex">
                                        <li class="breadcrumb__content--menu__items"><Link to="/">Home</Link></li>
                                        <li class="breadcrumb__content--menu__items"><span class="text__secondary">Repair Request</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- End breadcrumb section -->

<!-- Start Repair Request section --> */}
                <div class="privacy__policy--section section--padding">
                    <div class="container">
                        <div class="row">
                            <div class="col-12">
                                <div class="privacy__policy--content">
                                    {/* <!--<h2 class="privacy__policy--content__title">Who we are</h2>--> */}
                                    <p class="privacy__policy--content__desc">Weighing Scales / Weighing Machines, need to be constantly monitored is they are working fine. If you think, your Digital Weighing Scale is not up to mark, don’t hesitate to give us a call or fill the contact form below and let us get back to you. We have highly trained Service Technicians and Engineers who will be able to debug your scales and repair it at the most economical cost. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <!-- End Repair Request section --> */}

                <section class="contact__section section--padding">
                    <div class="container">
                        <div class="section__heading text-center mb-40">
                            <h2 class="section__heading--maintitle text__secondary mb-10">Contact us</h2>
                            <p class="section__heading--desc">Share your problem with us.</p>
                        </div>
                        <div class="main__contact--area">
                            <div class="row align-items-center row-md-reverse">
                                <div class="col-lg-12">
                                    <div class="contact__form">
                                        <form class="contact__form--inner" onSubmit={handleSubmit}>
                                            <div class="row">
                                                <div class="col-lg-12 col-md-6">
                                                    <div class="contact__form--list mb-20">
                                                        <label class="contact__form--label" for="input1">Name <span class="contact__form--label__star">*</span></label>
                                                        <input class="contact__form--input" name="names" id="input1" onChange={changeHandler} value={state.names} placeholder="Your First Name" type="text" />
                                                    </div>
                                                </div>

                                                <div class="col-lg-12 col-md-6">
                                                    <div class="contact__form--list mb-20">
                                                        <label class="contact__form--label" for="input3">Phone Number <span class="contact__form--label__star">*</span></label>
                                                        <input class="contact__form--input" name="phone" id="input3" onChange={changeHandler} value={state.phone} placeholder="Phone number" type="text" />
                                                    </div>
                                                </div>

                                                <div class="col-lg-12 col-md-6">
                                                    <div class="contact__form--list mb-20">
                                                        <label class="contact__form--label" for="input3">Email <span class="contact__form--label__star">*</span></label>
                                                        <input class="contact__form--input" name="email" id="input3" onChange={changeHandler} value={state.email} placeholder="Email" type="email" />
                                                    </div>
                                                </div>

                                                <div class="col-12">
                                                    <div class="contact__form--list mb-20">
                                                        <label class="contact__form--label" for="input5">Address <span class="contact__form--label__star">*</span></label>
                                                        <textarea class="contact__form--textarea" onChange={changeHandler} value={state.message} name="message" id="input5" placeholder="Write Your Message"></textarea>
                                                    </div>
                                                </div>

                                                <div class="col-lg-12 col-md-6">
                                                    <div class="contact__form--list mb-20">
                                                        <label class="contact__form--label" for="input3">Select Brand<span class="contact__form--label__star">*</span></label>
                                                        <select class="contact__form--input" onChange={changeHandler} value={state.brand} name='brand'>
                                                            <option selected>Select Brand</option>
                                                            <option value="Realweight">Realweight</option>
                                                            <option>Ind Casio</option>
                                                            <option>Digi Weight</option>
                                                            <option>RM lenevo</option>

                                                        </select>
                                                    </div>
                                                </div>

                                                <div class="col-lg-12 col-md-6">
                                                    <div class="contact__form--list mb-20">
                                                        <label class="contact__form--label" for="input3">Problem Description<span class="contact__form--label__star">*</span></label>
                                                        <select class="contact__form--input" name='problem' onChange={changeHandler} value={state.problem}>
                                                            <option selected>Select Brand Description</option>
                                                            <option>Scale is not turning on</option>
                                                            <option>Scale is showing incorrect weight</option>
                                                            <option>Scale only shows 888888</option>
                                                            <option>Scale shows only zero (0)</option>
                                                            <option>Weight is slowly increasing or decreasing</option>
                                                            <option>Buttons are not working</option>
                                                            <option>Scale doesn't shows stable weight</option>
                                                            <option>Display problem (Display get distorted while showing weight)</option>
                                                            <option>Scale is not working on battery or scale is not charging</option>
                                                            <option>Scales turns OFF while working on its own</option>
                                                            <option>Other</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="account__login--remember position__relative mb-15">
                                                <ReCAPTCHA
                                                    ref={captch}
                                                    sitekey={key}
                                                    onChange={onChange}
                                                />


                                            </div>
                                            <button class="contact__form--btn primary__btn" type="submit" name='submit' >Submit Now</button>
                                            {<p style={{ color: 'red' }}>{ercapacha}</p>}
                                            {<p style={{ color: 'green' }}>{status}</p>}
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </main>
        </div>

    )
}
export default Repair