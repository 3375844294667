import React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";

const Header = () => {

  const [isCollapsed, setIsCollapsed] = useState(true);



  const Sidebar = () => {
    setIsCollapsed(!isCollapsed);
    const element = document.getElementById("sidebar");
    const ele = document.getElementById("head");
    if (element) {
      element.classList.toggle('open');
      ele.classList.remove('mobile_menu_open');

    }

  }
  return (
    <div>
      <div>
        <div>
          {/* <!-- Start preloader --> */}

          <header class="header__section" id="head">
            {/* <!-- Start Header topbar --> */}
            <div class="header__topbar bg__primary color-primary-2">
              <div class="container">
                <div class="header__topbar--inner d-flex align-items-center justify-content-between">
                  <ul class="header__contact--info d-flex align-items-center">
                    <li class="header__contact--info__list text-white">
                      <svg
                        class="header__contact--info__icon"
                        xmlns="http://www.w3.org/2000/svg"
                        width="15.797"
                        height="20.05"
                        viewBox="0 0 512 512"
                      >
                        <path
                          d="M451 374c-15.88-16-54.34-39.35-73-48.76-24.3-12.24-26.3-13.24-45.4.95-12.74 9.47-21.21 17.93-36.12 14.75s-47.31-21.11-75.68-49.39-47.34-61.62-50.53-76.48 5.41-23.23 14.79-36c13.22-18 12.22-21 .92-45.3-8.81-18.9-32.84-57-48.9-72.8C119.9 44 119.9 47 108.83 51.6A160.15 160.15 0 0083 65.37C67 76 58.12 84.83 51.91 98.1s-9 44.38 23.07 102.64 54.57 88.05 101.14 134.49S258.5 406.64 310.85 436c64.76 36.27 89.6 29.2 102.91 23s22.18-15 32.83-31a159.09 159.09 0 0013.8-25.8C465 391.17 468 391.17 451 374z"
                          fill="currentColor"
                          stroke="currentColor"
                          stroke-miterlimit="10"
                          stroke-width="32"
                        />
                      </svg>
                      <Link to="tel:+917001507342">+917001507342</Link>
                    </li>
                    <li class="header__contact--info__list text-white">
                      <svg
                        class="header__contact--info__icon"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20.57"
                        height="13.13"
                        viewBox="0 0 31.57 31.13"
                      >
                        <path
                          d="M30.413,4H5.157C3.421,4,2.016,5.751,2.016,7.891L2,31.239c0,2.14,1.421,3.891,3.157,3.891H30.413c1.736,0,3.157-1.751,3.157-3.891V7.891C33.57,5.751,32.149,4,30.413,4Zm0,7.783L17.785,21.511,5.157,11.783V7.891l12.628,9.728L30.413,7.891Z"
                          transform="translate(-2 -4)"
                          fill="currentColor"
                        ></path>
                      </svg>
                      <Link to="mailto:rajibmarketing8@gmail.com">
                        rajibmarketing8@gmail.com
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* <!-- Start Header topbar -->

        <!-- Start main header --> */}
            <div class="main__header position__relative header__sticky">
              <div class="container">
                <div class="main__header--inner d-flex justify-content-between align-items-center">
                  <div class="offcanvas__header--menu__open ">
                    <Link
                      class="offcanvas__header--menu__open--btn"
                      to="javascript:void(0)"
                      data-offcanvas
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="ionicon offcanvas__header--menu__open--svg"
                        viewBox="0 0 512 512"
                      >
                        <path
                          fill="currentColor"
                          stroke="currentColor"
                          stroke-linecap="round"
                          stroke-miterlimit="10"
                          stroke-width="32"
                          d="M80 160h352M80 256h352M80 352h352"
                        />
                      </svg>
                      <span class="visually-hidden">Offcanvas Menu Open</span>
                    </Link>
                  </div>
                  <div class="main__logo">
                    <h1 class="main__logo--title">
                      <Link class="main__logo--link" to="/">
                        <img
                          class="main__logo--img"
                          src="/assets/img/logo.png"
                          style={{ height: "70px" }}
                          alt="logo-img"
                        />
                      </Link>
                    </h1>
                  </div>
                  
                  <div class="header__menu d-none d-lg-block">
                    <nav class="header__menu--navigation">
                      <ul class="d-flex">
                        <li class="header__menu--items">
                          <Link class="header__menu--link" to="/">
                            Home<span class="menu__plus--icon"></span>
                          </Link>
                        </li>
                        <li class="header__menu--items">
                          <Link class="header__menu--link" to="/About">
                            About Us{" "}
                          </Link>
                        </li>
                        <li class="header__menu--items">
                          <p class="header__menu--link">
                            Services <span class="menu__plus--icon"> +</span>
                          </p>
                          <ul class="header__sub--menu">
                            <li class="header__sub--menu__items">
                              <Link
                                to="/User"
                                class="header__sub--menu__link"
                              >
                                User Training
                              </Link>
                            </li>
                            <li class="header__sub--menu__items">
                              <Link
                                to="/Install"
                                class="header__sub--menu__link"
                              >
                                Installation Consultancy
                              </Link>
                            </li>
                            <li class="header__sub--menu__items">
                              <Link
                                to="/AMC"
                                class="header__sub--menu__link"
                              >
                                AMC Request
                              </Link>
                            </li>
                            <li class="header__sub--menu__items">
                              <Link
                                to="/Repair"
                                class="header__sub--menu__link"
                              >
                                Repair Request
                              </Link>
                            </li>
                          </ul>
                        </li>

                        <li class="header__menu--items">
                          <Link class="header__menu--link" to="/Category">
                            Our Products <span class="menu__plus--icon"></span>{" "}
                          </Link>
                        </li>

                        <li class="header__menu--items">
                          <Link class="header__menu--link" to="/Contact">
                            Contact{" "}
                          </Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                  <div class="header__account"></div>
                </div>
              </div>
            </div>
            {/* <!-- End main header -->

        <!-- Start Offcanvas header menu --> */}
            <div className="offcanvas-header" id="sidebar" tabIndex="-1" style={{ float: 'right' }}>

              <div class="offcanvas__inner">
                <div class="offcanvas__logo">
                  <Link class="offcanvas__logo_link" to="/">
                    <img src="/assets/img/logo.png" width="150px" height="60px" alt="Rokon Logo" />
                  </Link>
                  <button class="offcanvas__close--btn" data-offcanvas>
                    close
                  </button>

                </div>
                <nav class="offcanvas__menu">
                  <ul class="offcanvas__menu_ul">
                    <li class="offcanvas__menu_li">

                      <Link class="offcanvas__menu_item" to="/" onClick={Sidebar}>
                        Home
                      </Link>
                    </li>
                    <li class="offcanvas__menu_li">
                      <Link class="offcanvas__menu_item" to="/About" onClick={Sidebar}>
                        About Us
                      </Link>
                    </li>
                    <li class="offcanvas__menu_li">
                      <Link class="offcanvas__menu_item" to="/" onClick={Sidebar}>
                        Services
                      </Link>
                      <ul class="offcanvas__sub_menu">
                        <li class="offcanvas__sub_menu_li">
                          <Link to="/User" class="offcanvas__sub_menu_item" onClick={Sidebar}>
                            User Training
                          </Link>
                        </li>
                        <li class="offcanvas__sub_menu_li">
                          <Link
                            to="/Install"
                            class="offcanvas__sub_menu_item" onClick={Sidebar}
                          >
                            Installation Consultancy
                          </Link>
                        </li>
                        <li class="offcanvas__sub_menu_li">
                          <Link to="/AMC" class="offcanvas__sub_menu_item" onClick={Sidebar}>
                            AMC Request
                          </Link>
                        </li>
                        <li class="offcanvas__sub_menu_li">
                          <Link
                            to="/Repair"
                            class="offcanvas__sub_menu_item" onClick={Sidebar}
                          >
                            Repair Request
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li class="offcanvas__menu_li">
                      <Link class="offcanvas__menu_item" to="/Category" onClick={Sidebar}>
                        Category
                      </Link>
                    </li>
                    <li class="offcanvas__menu_li">
                      <Link class="offcanvas__menu_item" to="/Category" onClick={Sidebar}>
                        Products
                      </Link>
                      <ul class="offcanvas__sub_menu">
                        <li class="offcanvas__sub_menu_li">
                          <Link to="/Category" class="offcanvas__sub_menu_item" onClick={Sidebar}>
                            Real Weight
                          </Link>
                        </li>

                        <li class="offcanvas__sub_menu_li">
                          <Link
                            to="/Category"
                            class="offcanvas__sub_menu_item" onClick={Sidebar}
                          >
                            INDCASIO
                          </Link>
                        </li>
                        <li class="offcanvas__sub_menu_li">
                          <Link
                            to="/Category"
                            class="offcanvas__sub_menu_item" onClick={Sidebar}
                          >
                            RMLENEVO
                          </Link>
                        </li>
                        <li class="offcanvas__sub_menu_li">
                          <Link
                            to="/Category"
                            class="offcanvas__sub_menu_item" onClick={Sidebar}
                          >
                            DIGIWEIGHT
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li class="offcanvas__menu_li">
                      <Link class="offcanvas__menu_item" to="/Contact" onClick={Sidebar}>
                        Contact
                      </Link>
                    </li>
                  </ul>

                  <div class="language__currency"></div>
                </nav>
              </div>
            </div>
            {/* <!-- End Offcanvas header menu -->

        <!-- Start Offcanvas stikcy toolbar --> */}

            {/* <!-- End Offcanvas stikcy toolbar -->

        <!-- Start offCanvas minicart --> */}
            <div class="offCanvas__minicart" tabindex="-1">
              <div class="minicart__header ">
                <div class="minicart__header--top d-flex justify-content-between align-items-center">
                  <h3 class="minicart__title"> Shopping Cart</h3>
                  <button
                    class="minicart__close--btn"
                    aria-label="minicart close btn"
                    data-offcanvas
                  >
                    <svg
                      class="minicart__close--icon"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="32"
                        d="M368 368L144 144M368 144L144 368"
                      />
                    </svg>
                  </button>
                </div>
                <p class="minicart__header--desc">
                  The organic foods products are limited
                </p>
              </div>
              <div class="minicart__product">
                <div class="minicart__product--items d-flex">
                  <div class="minicart__thumbnail">
                    <Link to="product-details.php">
                      <img
                        src="/assets/img/product/small-product1.webp"
                        alt="prduct"
                      />
                    </Link>
                  </div>
                  <div class="minicart__text">
                    <h4 class="minicart__subtitle">
                      <Link to="product-details.php">TAdvanced Analytic</Link>
                    </h4>
                    <span class="color__variant">
                      <b>Color:</b> Beige
                    </span>
                    <div class="minicart__price">
                      <span class="current__price">$125.00</span>
                      <span class="old__price">$140.00</span>
                    </div>
                    <div class="minicart__text--footer d-flex align-items-center">
                      <div class="quantity__box minicart__quantity">
                        <button
                          type="button"
                          class="quantity__value decrease"
                          aria-label="quantity value"
                          value="Decrease Value"
                        >
                          -
                        </button>
                        <label>
                          <input
                            type="number"
                            class="quantity__number"
                            value="1"
                            data-counter
                          />
                        </label>
                        <button
                          type="button"
                          class="quantity__value increase"
                          aria-label="quantity value"
                          value="Increase Value"
                        >
                          +
                        </button>
                      </div>
                      <button
                        class="minicart__product--remove"
                        aria-label="minicart remove btn"
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                </div>
                <div class="minicart__product--items d-flex">
                  <div class="minicart__thumbnail">
                    <Link to="product-details.php">
                      <img
                        src="assets/img/product/small-product2.webp"
                        alt="prduct-img"
                      />
                    </Link>
                  </div>
                  <div class="minicart__text">
                    <h4 class="minicart__subtitle">
                      <Link to="product-details.php">Warrison Samuel.</Link>
                    </h4>
                    <span class="color__variant">
                      <b>Color:</b> Green
                    </span>
                    <div class="minicart__price">
                      <span class="current__price">$115.00</span>
                      <span class="old__price">$130.00</span>
                    </div>
                    <div class="minicart__text--footer d-flex align-items-center">
                      <div class="quantity__box minicart__quantity">
                        <button
                          type="button"
                          class="quantity__value decrease"
                          aria-label="quantity value"
                          value="Decrease Value"
                        >
                          -
                        </button>
                        <label>
                          <input
                            type="number"
                            class="quantity__number"
                            value="1"
                            data-counter
                          />
                        </label>
                        <button
                          type="button"
                          class="quantity__value increase"
                          aria-label="quantity value"
                          value="Increase Value"
                        >
                          +
                        </button>
                      </div>
                      <button
                        class="minicart__product--remove"
                        aria-label="minicart remove btn"
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="minicart__amount">
                <div class="minicart__amount_list d-flex justify-content-between">
                  <span>Sub Total:</span>
                  <span>
                    <b>$240.00</b>
                  </span>
                </div>
                <div class="minicart__amount_list d-flex justify-content-between">
                  <span>Total:</span>
                  <span>
                    <b>$240.00</b>
                  </span>
                </div>
              </div>
              <div class="minicart__conditions text-center">
                <input
                  class="minicart__conditions--input"
                  id="accept"
                  type="checkbox"
                />
                <label class="minicart__conditions--label" for="accept">
                  I agree with the{" "}
                  <Link
                    class="minicart__conditions--link"
                    to="privacy-policy.php"
                  >
                    Privacy And Policy
                  </Link>
                </label>
              </div>
              <div class="minicart__button d-flex justify-content-center">
                <Link class="primary__btn minicart__button--link" to="cart.php">
                  View cart
                </Link>
                <Link
                  class="primary__btn minicart__button--link"
                  to="checkout.php"
                >
                  Checkout
                </Link>
              </div>
            </div>
            {/* <!-- End offCanvas minicart -->

        <!-- Start serch box area --> */}
            <div class="predictive__search--box " tabindex="-1">
              <div class="predictive__search--box__inner">
                <h2 class="predictive__search--title">Search Products</h2>
                <form class="predictive__search--form" action="#">
                  <label>
                    <input
                      class="predictive__search--input"
                      placeholder="Search Here"
                      type="text"
                    />
                  </label>
                  <button
                    class="predictive__search--button"
                    aria-label="search button"
                  >
                    <svg
                      class="header__search--button__svg"
                      xmlns="http://www.w3.org/2000/svg"
                      width="30.51"
                      height="25.443"
                      viewBox="0 0 512 512"
                    >
                      <path
                        d="M221.09 64a157.09 157.09 0 10157.09 157.09A157.1 157.1 0 00221.09 64z"
                        fill="none"
                        stroke="currentColor"
                        stroke-miterlimit="10"
                        stroke-width="32"
                      />
                      <path
                        fill="none"
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-miterlimit="10"
                        stroke-width="32"
                        d="M338.29 338.29L448 448"
                      />
                    </svg>{" "}
                  </button>
                </form>
              </div>
              <button
                class="predictive__search--close__btn"
                aria-label="search close btn"
                data-offcanvas
              >
                <svg
                  class="predictive__search--close__icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="40.51"
                  height="30.443"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="32"
                    d="M368 368L144 144M368 144L144 368"
                  />
                </svg>
              </button>
            </div>
            {/* <!-- End serch box area --> */}
          </header>
          {/* <!-- End header area --> */}
        </div>
      </div>
    </div>
  );
};

export default Header;
