import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";


import axios from 'axios';
import { Helmet } from "react-helmet-async";

const Category = () => {


    const [product, setProdcut] = useState([]);
    const [activeTab, setActiveTab] = useState('description'); // Initialize the active tab



    const loadProdcut = async () => {
        const result = await axios.get("http://localhost/API/data.php");
        setProdcut(result.data.phpresult);
        console.log(result.data.phpresult)
    }

    useEffect(() => {
        loadProdcut();
    }, []);

    return (
        <div>

            <Helmet>
                <title>Category | RealWeight</title>
                <meta name="description" content="Realweight, established in 2015, is a trusted manufacturer and supplier of high-quality electronic weighing scales and scale spares. Our product range includes electronic table top scales, digital price computing scales, precision pocket scales, and more. We are committed to delivering durable, precise, and affordable weighing scale solutions. Choose Realweight for your weighing needs." />

                <meta property="og:title" content="RealWeight: Precision Weighing Scales Manufacturer & Supplier" />
                <meta property="og:site_name" content="RealWeight: Precision Weighing Scales Manufacturer & Supplier" />
                <meta property="og:url" content="https://realweight.in/" />
                <meta property="og:image" content="https://realweight.in/assets/img/logo.png" />
                <meta property="og:image:url" content="https://realweight.in/assets/img/logo.png" />
                <meta property="og:image:width" content="500" />
                <meta property="og:image:height" content="500" />
                <meta property="og:description"
                    content="Realweight, established in 2015, is a trusted manufacturer and supplier of high-quality electronic weighing scales and scale spares. Our product range includes electronic table top scales, digital price computing scales, precision pocket scales, and more. We are committed to delivering durable, precise, and affordable weighing scale solutions. Choose Realweight for your weighing needs." />

            </Helmet>
            <main class="main__content_wrapper">
                {/* <!-- Start breadcrumb section --> */}
                <section class="breadcrumb__section breadcrumb__bg">
                    <div class="container">
                        <div class="row row-cols-1">
                            <div class="col">
                                <div class="breadcrumb__content">
                                    <h1 class="breadcrumb__content--title mb-10">Our Products</h1>
                                    <ul class="breadcrumb__content--menu d-flex">
                                        <li class="breadcrumb__content--menu__items">
                                            <Link to="/">Home</Link>
                                        </li>
                                        <li class="breadcrumb__content--menu__items">
                                            <span class="text__secondary">Our Products</span>
                                        </li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <!-- End breadcrumb section -->

        

<!-- Start shop section --> */}


                {/* <!-- Start shop section --> */}
                <section className="project__section project__section--bg section--padding">
                    <div className="container">
                        <div class="section__heading text-center mb-20">
                        </div>
                        <div className="project__tab--container">
                            <div className="project__tab--container">
                                <ul className="project__tab--one project__tab--btn d-flex justify-content-center mb-40">
                                    <li
                                        className={`project__tab--btn__list  p-10 ${activeTab === 'description' ? 'active' : ''}`}
                                        onClick={() => setActiveTab('description')}
                                    >
                                        Realweight
                                    </li>
                                    <li
                                        className={`project__tab--btn__list ${activeTab === 'reviews' ? 'active' : ''}`}
                                        onClick={() => setActiveTab('reviews')}
                                    >
                                        rmLenevo

                                    </li>
                                    <li
                                        className={`project__tab--btn__list  ${activeTab === 'information' ? 'active' : ''}`}
                                        onClick={() => setActiveTab('information')}
                                    >
                                        Digi Weight
                                    </li>
                                    <li
                                        className={`project__tab--btn__list ${activeTab === 'casio' ? 'active' : ''}`}
                                        onClick={() => setActiveTab('casio')}
                                    >
                                        IndCasio
                                    </li>
                                </ul>
                                <div className="product__details--tab__inner border-radius-10">
                                    <div className="tab_content">
                                        <div
                                            id="description"
                                            className={`tab_pane ${activeTab === 'description' ? 'active show' : ''}`}
                                        >
                                            <div class="project__section--inner">
                                                <div class="row row-cols-md-3 row-cols-2 mb--n30">

                                                    <div class="col custom-col-2 mb-30">
                                                        <article class="project__card ">
                                                            <Link class="project__card--link" to="product?comp=1&cate=1 ">

                                                                <div class="project__card--thumbnail">
                                                                    <img class="project__card--thumbnail__img display-block" src="assets/img/category/1_1.png" alt="product-img" />
                                                                </div>
                                                                <div class="project__card--content d-flex justify-content-between align-items-center">
                                                                    <div class="project__card--content__left">
                                                                        <h3 class="project__card--content__title">Jewellery Scale</h3>
                                                                        {/* <!--<span class="project__card--content__subtitle">RWJ 300/600 gm</span>--> */}
                                                                    </div>
                                                                    <span class="project__card--btn"><svg class="project__card--btn__svg" xmlns="http://www.w3.org/2000/svg" width="15.51" height="15.443" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M268 112l144 144-144 144M392 256H100"></path></svg></span>
                                                                </div>
                                                            </Link>
                                                        </article>
                                                    </div>

                                                    <div class="col custom-col-2 mb-30">
                                                        <article class="project__card ">
                                                            <Link class="project__card--link" to="product?comp=1&cate=2">
                                                                <div class="project__card--thumbnail">
                                                                    <img class="project__card--thumbnail__img display-block" src="assets/img/category/1_2.png" alt="product-img" />
                                                                </div>
                                                                <div class="project__card--content d-flex justify-content-between align-items-center">
                                                                    <div class="project__card--content__left">
                                                                        <h3 class="project__card--content__title">Table Top Scale</h3>
                                                                        {/* <!--<span class="project__card--content__subtitle">CEO - Founder</span>--> */}
                                                                    </div>
                                                                    <span class="project__card--btn"><svg class="project__card--btn__svg" xmlns="http://www.w3.org/2000/svg" width="15.51" height="15.443" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M268 112l144 144-144 144M392 256H100"></path></svg></span>
                                                                </div>
                                                            </Link>
                                                        </article>
                                                    </div>
                                                    <div class="col custom-col-2 mb-30">
                                                        <article class="project__card ">
                                                            <Link class="project__card--link" to="product?comp=1&cate=3">
                                                                <div class="project__card--thumbnail">
                                                                    <img class="project__card--thumbnail__img display-block" src="assets/img/category/1_3.png" alt="product-img" />
                                                                </div>
                                                                <div class="project__card--content d-flex justify-content-between align-items-center">
                                                                    <div class="project__card--content__left">
                                                                        <h3 class="project__card--content__title">Price Computing</h3>
                                                                        {/* <!--<span class="project__card--content__subtitle">Spa Manager</span>--> */}
                                                                    </div>
                                                                    <span class="project__card--btn"><svg class="project__card--btn__svg" xmlns="http://www.w3.org/2000/svg" width="15.51" height="15.443" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M268 112l144 144-144 144M392 256H100"></path></svg></span>
                                                                </div>
                                                            </Link>
                                                        </article>
                                                    </div>

                                                    <div class="col custom-col-2 mb-30">
                                                        <article class="project__card ">
                                                            <Link class="project__card--link" to="product?comp=1&cate=4">
                                                                <div class="project__card--thumbnail">
                                                                    <img class="project__card--thumbnail__img display-block" src="assets/img/category/1_4.png" alt="product-img" />
                                                                </div>
                                                                <div class="project__card--content d-flex justify-content-between align-items-center">
                                                                    <div class="project__card--content__left">
                                                                        <h3 class="project__card--content__title">Platform Scale</h3>
                                                                        {/* <!--<span class="project__card--content__subtitle">Spa Manager</span>--> */}
                                                                    </div>
                                                                    <span class="project__card--btn"><svg class="project__card--btn__svg" xmlns="http://www.w3.org/2000/svg" width="15.51" height="15.443" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M268 112l144 144-144 144M392 256H100"></path></svg></span>
                                                                </div>
                                                            </Link>
                                                        </article>
                                                    </div>

                                                    <div class="col custom-col-2 mb-30">
                                                        <article class="project__card ">
                                                            <Link class="project__card--link" to="product?comp=1&cate=5">
                                                                <div class="project__card--thumbnail">
                                                                    <img class="project__card--thumbnail__img display-block" src="assets/img/category/1_5.png" alt="product-img" />
                                                                </div>
                                                                <div class="project__card--content d-flex justify-content-between align-items-center">
                                                                    <div class="project__card--content__left">
                                                                        <h3 class="project__card--content__title">Bench Type Scale</h3>
                                                                        {/* <!--<span class="project__card--content__subtitle">Spa Manager</span>--> */}
                                                                    </div>
                                                                    <span class="project__card--btn"><svg class="project__card--btn__svg" xmlns="http://www.w3.org/2000/svg" width="15.51" height="15.443" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M268 112l144 144-144 144M392 256H100"></path></svg></span>
                                                                </div>
                                                            </Link>
                                                        </article>
                                                    </div>

                                                    <div class="col custom-col-2 mb-30">
                                                        <article class="project__card ">
                                                            <Link to='product?comp=1&cate=6' className="project__card--link">
                                                                <div class="project__card--thumbnail">
                                                                    <img class="project__card--thumbnail__img display-block" src="assets/img/category/1_6.png" alt="product-img" />
                                                                </div>
                                                                <div class="project__card--content d-flex justify-content-between align-items-center">
                                                                    <div class="project__card--content__left">
                                                                        <h3 class="project__card--content__title">Hanging Scale</h3>
                                                                        {/* <!--<span class="project__card--content__subtitle">Spa Manager</span>--> */}
                                                                    </div>
                                                                    <span class="project__card--btn"><svg class="project__card--btn__svg" xmlns="http://www.w3.org/2000/svg" width="15.51" height="15.443" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M268 112l144 144-144 144M392 256H100"></path></svg></span>
                                                                </div>
                                                            </Link>
                                                        </article>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            id="reviews"
                                            className={`tab_pane ${activeTab === 'reviews' ? 'active show' : ''}`}
                                        >
                                            <div class="project__section--inner">
                                                <div class="row row-cols-md-3 row-cols-2 mb--n30">
                                                    <div class="col custom-col-2 mb-30">
                                                        <article class="project__card ">
                                                            <Link class="project__card--link" to="/product?comp=2&cate=1">
                                                                <div class="project__card--thumbnail">
                                                                    <img class="project__card--thumbnail__img display-block" src="assets/img/category/4_1.png" alt="product-img" />
                                                                </div>
                                                                <div class="project__card--content d-flex justify-content-between align-items-center">
                                                                    <div class="project__card--content__left">
                                                                        <h3 class="project__card--content__title">Table Top Scale</h3>
                                                                        {/* <!--<span class="project__card--content__subtitle">RWJ 300/600 gm</span>--> */}
                                                                    </div>
                                                                    <span class="project__card--btn"><svg class="project__card--btn__svg" xmlns="http://www.w3.org/2000/svg" width="15.51" height="15.443" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M268 112l144 144-144 144M392 256H100"></path></svg></span>
                                                                </div>
                                                            </Link>
                                                        </article>
                                                    </div>
                                                    <div class="col custom-col-2 mb-30">
                                                        <article class="project__card ">
                                                            <Link class="project__card--link" to="product?comp=2&cate=2">
                                                                <div class="project__card--thumbnail">
                                                                    <img class="project__card--thumbnail__img display-block" src="assets/img/category/4_2.png" alt="product-img" />
                                                                </div>
                                                                <div class="project__card--content d-flex justify-content-between align-items-center">
                                                                    <div class="project__card--content__left">
                                                                        <h3 class="project__card--content__title">Platform Scale</h3>
                                                                        {/* <!--<span class="project__card--content__subtitle">CEO - Founder</span>--> */}
                                                                    </div>
                                                                    <span class="project__card--btn"><svg class="project__card--btn__svg" xmlns="http://www.w3.org/2000/svg" width="15.51" height="15.443" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M268 112l144 144-144 144M392 256H100"></path></svg></span>
                                                                </div>
                                                            </Link>
                                                        </article>
                                                    </div>
                                                    <div class="col custom-col-2 mb-30">
                                                        <article class="project__card ">
                                                            <Link class="project__card--link" to="product?comp=2&cate=3">
                                                                <div class="project__card--thumbnail">
                                                                    <img class="project__card--thumbnail__img display-block" src="assets/img/category/4_3.png" alt="product-img" />
                                                                </div>
                                                                <div class="project__card--content d-flex justify-content-between align-items-center">
                                                                    <div class="project__card--content__left">
                                                                        <h3 class="project__card--content__title">Bench Type Scale</h3>
                                                                        {/* <!--<span class="project__card--content__subtitle">Spa Manager</span>--> */}
                                                                    </div>
                                                                    <span class="project__card--btn"><svg class="project__card--btn__svg" xmlns="http://www.w3.org/2000/svg" width="15.51" height="15.443" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M268 112l144 144-144 144M392 256H100"></path></svg></span>
                                                                </div>
                                                            </Link>
                                                        </article>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            id="casio"
                                            className={`tab_pane ${activeTab === 'casio' ? 'active show' : ''}`}
                                        >
                                            <div class="project__section--inner">
                                                <div class="row row-cols-md-3 row-cols-2 mb--n30">
                                                    <div class="col custom-col-2 mb-30">
                                                        <article class="project__card ">
                                                            <Link class="project__card--link" to="product?comp=4&cate=1">
                                                                <div class="project__card--thumbnail">
                                                                    <img class="project__card--thumbnail__img display-block" src="assets/img/category/2_1.png" alt="product-img" />
                                                                </div>
                                                                <div class="project__card--content d-flex justify-content-between align-items-center">
                                                                    <div class="project__card--content__left">
                                                                        <h3 class="project__card--content__title">Table Top Scale</h3>
                                                                        {/* <!--<span class="project__card--content__subtitle">RWJ 300/600 gm</span>--> */}
                                                                    </div>
                                                                    <span class="project__card--btn"><svg class="project__card--btn__svg" xmlns="http://www.w3.org/2000/svg" width="15.51" height="15.443" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M268 112l144 144-144 144M392 256H100"></path></svg></span>
                                                                </div>
                                                            </Link>
                                                        </article>
                                                    </div>
                                                    <div class="col custom-col-2 mb-30">
                                                        <article class="project__card ">
                                                            <Link class="project__card--link" to="product?comp=4&cate=2">
                                                                <div class="project__card--thumbnail">
                                                                    <img class="project__card--thumbnail__img display-block" src="assets/img/category/2_2.png" alt="product-img" />
                                                                </div>
                                                                <div class="project__card--content d-flex justify-content-between align-items-center">
                                                                    <div class="project__card--content__left">
                                                                        <h3 class="project__card--content__title">Platform Scale</h3>
                                                                        {/* <!--<span class="project__card--content__subtitle">Spa Manager</span>--> */}
                                                                    </div>
                                                                    <span class="project__card--btn"><svg class="project__card--btn__svg" xmlns="http://www.w3.org/2000/svg" width="15.51" height="15.443" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M268 112l144 144-144 144M392 256H100"></path></svg></span>
                                                                </div>
                                                            </Link>
                                                        </article>
                                                    </div>
                                                    <div class="col custom-col-2 mb-30">
                                                        <article class="project__card ">
                                                            <Link class="project__card--link" to="product?comp=4&cate=3">
                                                                <div class="project__card--thumbnail">
                                                                    <img class="project__card--thumbnail__img display-block" src="assets/img/category/2_3.png" alt="product-img" />
                                                                </div>
                                                                <div class="project__card--content d-flex justify-content-between align-items-center">
                                                                    <div class="project__card--content__left">
                                                                        <h3 class="project__card--content__title">Bench Type Scale</h3>
                                                                        {/* <!--<span class="project__card--content__subtitle">CEO - Founder</span>--> */}
                                                                    </div>
                                                                    <span class="project__card--btn"><svg class="project__card--btn__svg" xmlns="http://www.w3.org/2000/svg" width="15.51" height="15.443" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M268 112l144 144-144 144M392 256H100"></path></svg></span>
                                                                </div>
                                                            </Link>
                                                        </article>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        <div
                                            id="information"
                                            className={`tab_pane ${activeTab === 'information' ? 'active show' : ''}`}
                                        >
                                            <div class="project__section--inner">
                                                <div class="row row-cols-md-3 row-cols-2 mb--n30">
                                                    <div class="col custom-col-2 mb-30">
                                                        <article class="project__card ">
                                                            <Link class="project__card--link" to="product?comp=3&cate=1">
                                                                <div class="project__card--thumbnail">
                                                                    <img class="project__card--thumbnail__img display-block" src="assets/img/category/3_1.png" alt="product-img" />
                                                                </div>
                                                                <div class="project__card--content d-flex justify-content-between align-items-center">
                                                                    <div class="project__card--content__left">
                                                                        <h3 class="project__card--content__title">Table Top Scale</h3>
                                                                        {/* <!--<span class="project__card--content__subtitle">RWJ 300/600 gm</span>--> */}
                                                                    </div>
                                                                    <span class="project__card--btn"><svg class="project__card--btn__svg" xmlns="http://www.w3.org/2000/svg" width="15.51" height="15.443" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M268 112l144 144-144 144M392 256H100"></path></svg></span>
                                                                </div>
                                                            </Link>
                                                        </article>
                                                    </div>

                                                    <div class="col custom-col-2 mb-30">
                                                        <article class="project__card ">
                                                            <Link class="project__card--link" to="product?comp=3&cate=2">
                                                                <div class="project__card--thumbnail">
                                                                    <img class="project__card--thumbnail__img display-block" src="assets/img/category/3_2.png" alt="product-img" />
                                                                </div>
                                                                <div class="project__card--content d-flex justify-content-between align-items-center">
                                                                    <div class="project__card--content__left">
                                                                        <h3 class="project__card--content__title">Platform Scale</h3>
                                                                        {/* <!--<span class="project__card--content__subtitle">Spa Manager</span>--> */}
                                                                    </div>
                                                                    <span class="project__card--btn"><svg class="project__card--btn__svg" xmlns="http://www.w3.org/2000/svg" width="15.51" height="15.443" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M268 112l144 144-144 144M392 256H100"></path></svg></span>
                                                                </div>
                                                            </Link>
                                                        </article>
                                                    </div>
                                                    <div class="col custom-col-2 mb-30">
                                                        <article class="project__card ">
                                                            <Link class="project__card--link" to="product?comp=3&cate=3">
                                                                <div class="project__card--thumbnail">
                                                                    <img class="project__card--thumbnail__img display-block" src="assets/img/category/3_3.png" alt="product-img" />
                                                                </div>
                                                                <div class="project__card--content d-flex justify-content-between align-items-center">
                                                                    <div class="project__card--content__left">
                                                                        <h3 class="project__card--content__title">Bench Type Scale</h3>
                                                                        {/* <!--<span class="project__card--content__subtitle">CEO - Founder</span>--> */}
                                                                    </div>
                                                                    <span class="project__card--btn"><svg class="project__card--btn__svg" xmlns="http://www.w3.org/2000/svg" width="15.51" height="15.443" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M268 112l144 144-144 144M392 256H100"></path></svg></span>
                                                                </div>
                                                            </Link>
                                                        </article>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


            </main>


        </div>


    );
};
export default Category;