
import React from 'react'

import { Link } from "react-router-dom";


const Footer = () => {



    return (
        <div>


            {/* <!-- Start footer section --> */}

            <footer class="footer__section footer__bg pb-0">
                <div class="container">
                    <div class="main__footer section--padding">
                        <div class="row rows">
                            <div class="col-lg-3 col-md-6">
                                <div class="footer__widget">
                                    <h2 class="footer__widget--title d-none d-md-block">About Us <button class="footer__widget--button" aria-label="footer widget button"></button>
                                        {/* <svg class="footer__widget--title__arrowdown--icon" xmlns="http://www.w3.org/2000/svg" width="12.355" height="8.394" viewBox="0 0 10.355 6.394">
                                            <path d="M15.138,8.59l-3.961,3.952L7.217,8.59,6,9.807l5.178,5.178,5.178-5.178Z" transform="translate(-6 -8.59)" fill="currentColor"></path>
                                        </svg> */}
                                    </h2>
                                    <div class="footer__widget--inner">
                                        <Link class="footer__logo" to="/"><img class="footer__logo--img display-block" src="/assets/img/logo.png" style={{ height: "100px" }} alt="footer-logo" /></Link>
                                        <p class="footer__widget--desc" style={{ textAlign: "justify" }}>Realweight - Since 2015, a trusted manufacturer, exporter, and supplier of precision Electronic Weighing Scales and reliable Scales Spares. Quality meets affordability.</p>
                                        <div class="footer__social">
                                            <ul class="social__shear d-flex">
                                                <li class="social__shear--list">
                                                    <Link class="social__shear--list__icon" target="_blank" to="https://www.facebook.com">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="8.239" height="15.984" viewBox="0 0 11.239 20.984">
                                                            <path id="Icon_awesome-facebook-f" data-name="Icon awesome-facebook-f" d="M11.575,11.8l.583-3.8H8.514V5.542A1.9,1.9,0,0,1,10.655,3.49h1.657V.257A20.2,20.2,0,0,0,9.371,0c-3,0-4.962,1.819-4.962,5.112V8.006H1.073v3.8H4.409v9.181H8.514V11.8Z" transform="translate(-1.073)" fill="currentColor" />
                                                        </svg>
                                                        <span class="visually-hidden">Facebook</span>
                                                    </Link>
                                                </li>
                                                <li class="social__shear--list">
                                                    <Link class="social__shear--list__icon" target="_blank" to="https://twitter.com">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="14.492" viewBox="0 0 24 19.492">
                                                            <path id="Icon_awesome-twitter" data-name="Icon awesome-twitter" d="M21.533,7.112c.015.213.015.426.015.64A13.9,13.9,0,0,1,7.553,21.746,13.9,13.9,0,0,1,0,19.538a10.176,10.176,0,0,0,1.188.061,9.851,9.851,0,0,0,6.107-2.1,4.927,4.927,0,0,1-4.6-3.411,6.2,6.2,0,0,0,.929.076,5.2,5.2,0,0,0,1.294-.167A4.919,4.919,0,0,1,.975,9.168V9.107A4.954,4.954,0,0,0,3.2,9.731,4.926,4.926,0,0,1,1.675,3.152,13.981,13.981,0,0,0,11.817,8.3,5.553,5.553,0,0,1,11.7,7.173a4.923,4.923,0,0,1,8.513-3.365A9.684,9.684,0,0,0,23.33,2.619,4.906,4.906,0,0,1,21.167,5.33,9.861,9.861,0,0,0,24,4.569a10.573,10.573,0,0,1-2.467,2.543Z" transform="translate(0 -2.254)" fill="currentColor" />
                                                        </svg>
                                                        <span class="visually-hidden">Twitter</span>
                                                    </Link>
                                                </li>
                                                <li class="social__shear--list">
                                                    <Link class="social__shear--list__icon" target="_blank" to="https://www.instagram.com">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14.497" height="14.492" viewBox="0 0 19.497 19.492">
                                                            <path id="Icon_awesome-instagram" data-name="Icon awesome-instagram" d="M9.747,6.24a5,5,0,1,0,5,5A4.99,4.99,0,0,0,9.747,6.24Zm0,8.247A3.249,3.249,0,1,1,13,11.238a3.255,3.255,0,0,1-3.249,3.249Zm6.368-8.451A1.166,1.166,0,1,1,14.949,4.87,1.163,1.163,0,0,1,16.115,6.036Zm3.31,1.183A5.769,5.769,0,0,0,17.85,3.135,5.807,5.807,0,0,0,13.766,1.56c-1.609-.091-6.433-.091-8.042,0A5.8,5.8,0,0,0,1.64,3.13,5.788,5.788,0,0,0,.065,7.215c-.091,1.609-.091,6.433,0,8.042A5.769,5.769,0,0,0,1.64,19.341a5.814,5.814,0,0,0,4.084,1.575c1.609.091,6.433.091,8.042,0a5.769,5.769,0,0,0,4.084-1.575,5.807,5.807,0,0,0,1.575-4.084c.091-1.609.091-6.429,0-8.038Zm-2.079,9.765a3.289,3.289,0,0,1-1.853,1.853c-1.283.509-4.328.391-5.746.391S5.28,19.341,4,18.837a3.289,3.289,0,0,1-1.853-1.853c-.509-1.283-.391-4.328-.391-5.746s-.113-4.467.391-5.746A3.289,3.289,0,0,1,4,3.639c1.283-.509,4.328-.391,5.746-.391s4.467-.113,5.746.391a3.289,3.289,0,0,1,1.853,1.853c.509,1.283.391,4.328.391,5.746S17.855,15.705,17.346,16.984Z" transform="translate(0.004 -1.492)" fill="currentColor" />
                                                        </svg>
                                                        <span class="visually-hidden">Instagram</span>
                                                    </Link>
                                                </li>
                                                <li class="social__shear--list">
                                                    <Link class="social__shear--list__icon" target="_blank" to="https://www.linkedin.com">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14.419" height="14.419" viewBox="0 0 19.419 19.419">
                                                            <path id="Icon_awesome-linkedin-in" data-name="Icon awesome-linkedin-in" d="M4.347,19.419H.321V6.454H4.347ZM2.332,4.686A2.343,2.343,0,1,1,4.663,2.332,2.351,2.351,0,0,1,2.332,4.686ZM19.415,19.419H15.4V13.108c0-1.5-.03-3.433-2.093-3.433-2.093,0-2.414,1.634-2.414,3.325v6.42H6.869V6.454H10.73V8.223h.056A4.23,4.23,0,0,1,14.6,6.129c4.075,0,4.824,2.683,4.824,6.168v7.122Z" fill="currentColor" />
                                                        </svg>
                                                        <span class="visually-hidden">Linkedin</span>
                                                    </Link>
                                                </li>
                                                <li class="social__shear--list">
                                                    <Link class="social__shear--list__icon" target="_blank" to="https://www.linkedin.com">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="15.49" height="15.582" viewBox="0 0 16.49 11.582">
                                                            <path data-name="Path 321" d="M967.759,1365.592q0,1.377-.019,1.717-.076,1.114-.151,1.622a3.981,3.981,0,0,1-.245.925,1.847,1.847,0,0,1-.453.717,2.171,2.171,0,0,1-1.151.6q-3.585.265-7.641.189-2.377-.038-3.387-.085a11.337,11.337,0,0,1-1.5-.142,2.206,2.206,0,0,1-1.113-.585,2.562,2.562,0,0,1-.528-1.037,3.523,3.523,0,0,1-.141-.585c-.032-.2-.06-.5-.085-.906a38.894,38.894,0,0,1,0-4.867l.113-.925a4.382,4.382,0,0,1,.208-.906,2.069,2.069,0,0,1,.491-.755,2.409,2.409,0,0,1,1.113-.566,19.2,19.2,0,0,1,2.292-.151q1.82-.056,3.953-.056t3.952.066q1.821.067,2.311.142a2.3,2.3,0,0,1,.726.283,1.865,1.865,0,0,1,.557.49,3.425,3.425,0,0,1,.434,1.019,5.72,5.72,0,0,1,.189,1.075q0,.095.057,1C967.752,1364.1,967.759,1364.677,967.759,1365.592Zm-7.6.925q1.49-.754,2.113-1.094l-4.434-2.339v4.66Q958.609,1367.311,960.156,1366.517Z" transform="translate(-951.269 -1359.8)" fill="currentColor"></path>
                                                        </svg>
                                                        <span class="visually-hidden">Youtube</span>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6">
                                <div class="footer__widget">
                                    <h2 class="footer__widget--title ">Useful Links <button class="footer__widget--button" aria-label="footer widget button"></button>
                                        {/* <svg class="footer__widget--title__arrowdown--icon" xmlns="http://www.w3.org/2000/svg" width="12.355" height="8.394" viewBox="0 0 10.355 6.394">
                                            <path d="M15.138,8.59l-3.961,3.952L7.217,8.59,6,9.807l5.178,5.178,5.178-5.178Z" transform="translate(-6 -8.59)" fill="currentColor"></path>
                                        </svg> */}
                                    </h2>
                                    <ul class="footer__widget--menu footer__widget--inner">
                                        <li class="footer__widget--menu__list"><Link class="footer__widget--menu__text" to="/About">About Us</Link></li>
                                        <li class="footer__widget--menu__list"><Link class="footer__widget--menu__text" to="/User">Services</Link></li>
                                        <li class="footer__widget--menu__list"><Link class="footer__widget--menu__text" to="/Contact">Contact Us</Link></li>
                                        <li class="footer__widget--menu__list"><Link class="footer__widget--menu__text" to="/Category">Our Products</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6">
                                <div class="footer__widget">
                                    <h2 class="footer__widget--title ">Serives<button class="footer__widget--button" aria-label="footer widget button"></button>
                                        {/* <svg class="footer__widget--title__arrowdown--icon" xmlns="http://www.w3.org/2000/svg" width="12.355" height="8.394" viewBox="0 0 10.355 6.394">
                                            <path d="M15.138,8.59l-3.961,3.952L7.217,8.59,6,9.807l5.178,5.178,5.178-5.178Z" transform="translate(-6 -8.59)" fill="currentColor"></path>
                                        </svg> */}
                                    </h2>
                                    <ul class="footer__widget--menu footer__widget--inner">
                                        <li class="footer__widget--menu__list"><Link class="footer__widget--menu__text" to="/User" >User Training</Link></li>
                                        <li class="footer__widget--menu__list"><Link class="footer__widget--menu__text" to="/Install">Installation Consultancy</Link></li>
                                        <li class="footer__widget--menu__list"><Link class="footer__widget--menu__text" to="/AMC">AMC Request</Link></li>
                                        <li class="footer__widget--menu__list"><Link class="footer__widget--menu__text" to="/Repair">Repair Request</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-6">
                                <div class="footer__widget">
                                    <h2 class="footer__widget--title ">Contact Us <button class="footer__widget--button" aria-label="footer widget button"></button>
                                        {/* <svg class="footer__widget--title__arrowdown--icon" xmlns="http://www.w3.org/2000/svg" width="12.355" height="8.394" viewBox="0 0 10.355 6.394">
                                            <path d="M15.138,8.59l-3.961,3.952L7.217,8.59,6,9.807l5.178,5.178,5.178-5.178Z" transform="translate(-6 -8.59)" fill="currentColor"></path>
                                        </svg> */}
                                    </h2>
                                    <div class="footer__newsletter footer__widget--inner">
                                        <div class="border-radius-10">
                                            <div class="contact__info--items">
                                                {/* <!--<h3 class="contact__info--content__title text-white mb-15">Contact Us</h3>--> */}
                                                <div class="contact__info--items__inner d-flex">
                                                    <div class="contact__info--icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="10.57" height="10.57" viewBox="0 0 31.568 31.128">
                                                            <path id="ic_phone_forwarded_24px" d="M26.676,16.564l7.892-7.782L26.676,1V5.669H20.362v6.226h6.314Zm3.157,7a18.162,18.162,0,0,1-5.635-.887,1.627,1.627,0,0,0-1.61.374l-3.472,3.424a23.585,23.585,0,0,1-10.4-10.257l3.472-3.44a1.48,1.48,0,0,0,.395-1.556,17.457,17.457,0,0,1-.9-5.556A1.572,1.572,0,0,0,10.1,4.113H4.578A1.572,1.572,0,0,0,3,5.669,26.645,26.645,0,0,0,29.832,32.128a1.572,1.572,0,0,0,1.578-1.556V25.124A1.572,1.572,0,0,0,29.832,23.568Z" transform="translate(-3 -1)" fill="currentColor"></path>
                                                        </svg>
                                                    </div>
                                                    <div class="contact__info--content">
                                                        <p class="contact__info--content__desc text-white"><a href="tel:+919733685047">+91 9733685047</a><br /> <a href="tel:+917001507342">+91 7001507342</a>   </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="contact__info--items">
                                                {/* <!--<h3 class="contact__info--content__title text-white mb-15">Email Address</h3>--> */}
                                                <div class="contact__info--items__inner d-flex">
                                                    <div class="contact__info--icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="10.57" height="10.57" viewBox="0 0 31.57 31.13">
                                                            <path id="ic_email_24px" d="M30.413,4H5.157C3.421,4,2.016,5.751,2.016,7.891L2,31.239c0,2.14,1.421,3.891,3.157,3.891H30.413c1.736,0,3.157-1.751,3.157-3.891V7.891C33.57,5.751,32.149,4,30.413,4Zm0,7.783L17.785,21.511,5.157,11.783V7.891l12.628,9.728L30.413,7.891Z" transform="translate(-2 -4)" fill="currentColor"></path>
                                                        </svg>
                                                    </div>
                                                    <div class="contact__info--content">
                                                        <p class="contact__info--content__desc text-white"> <a href="mailto:info@example.com">rajibmarketing8@gmail.com</a></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="contact__info--items">
                                                <div class="contact__info--items__inner d-flex">
                                                    <div class="contact__info--icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="10.57" height="10.13" viewBox="0 0 31.57 31.13">
                                                            <path id="ic_account_balance_24px" d="M5.323,14.341V24.718h4.985V14.341Zm9.969,0V24.718h4.985V14.341ZM2,32.13H33.57V27.683H2ZM25.262,14.341V24.718h4.985V14.341ZM17.785,1,2,8.412v2.965H33.57V8.412Z" transform="translate(-2 -1)" fill="currentColor"></path>
                                                        </svg>
                                                    </div>
                                                    <div class="contact__info--content">
                                                        <p class="contact__info--content__desc text-white">  Realweight, Near Lila Medical, Duttapulia, West Bengal</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* <!--<p class="footer__newsletter--desc">+91 9733685047</p>-->
                                        <!--<p class="footer__newsletter--desc">+91 7001507342</p>-->
                                        <!--<p class="footer__newsletter--desc">rajibmarketing8@gmail.com</p>-->
                                        <!--<p class="footer__newsletter--desc">Realweight, near lila medical, Duttapulia, West Bengal</p>-->
                                        <!--<form class="newsletter__subscribe--form__style position__relative" action="#">-->
                                            <!--    <label>-->
                                                <!--        <input class="footer__newsletter--input newsletter__subscribe--input" placeholder="Enter your email address" type="email">-->
                                                    <!--    </label>-->
                                            <!--    <button class="footer__newsletter--button newsletter__subscribe--button primary__btn" type="submit">Subscribe-->
                                                <!--        <svg class="newsletter__subscribe--button__icon" xmlns="http://www.w3.org/2000/svg" width="9.159" height="7.85" viewBox="0 0 9.159 7.85">-->
                                                    <!--            <path data-name="Icon material-send" d="M3,12.35l9.154-3.925L3,4.5,3,7.553l6.542.872L3,9.3Z" transform="translate(-3 -4.5)" fill="currentColor" />-->
                                                    <!--        </svg>-->
                                                <!--    </button>-->
                                            <!--</form> --> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="footer__bottom d-flex justify-content-between align-items-center">
                        <p class="copyright__content  m-0">All Rights Reserved by RealWeight @ 2023</p>
                        <p class="footer__bottom--desc">Managed by <a target='_blank' href="https://gurusoftware.in/" >Guru Software</a></p>
                    </div>
                </div>
            </footer>
            {/* <!-- End footer section -->

     <!-- Start News letter popup --> */}
            {/*      
    <!-- End News letter popup -->

    <!-- Scroll top bar --> */}
            {/* <button aria-label="scroll top btn" id="scroll__top"><svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="48" d="M112 244l144-144 144 144M256 120v292" /></svg></button> */}
            <a href="https://wa.me/917001507342?text=I%20want%20to%20know%20more%20details%20about%20Realweight" class="float" target="_blank">
                <img src="/assets/img/whatsapp.png" alt="wp" class="my-float" />
            </a>


        </div>
    )
}

export default Footer
