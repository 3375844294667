import React from 'react';

const Loader = () => {
    return (
        <div className="preloader" style={{padding:"100px"}}>
            <div className='container' >
                <div className="card">
                    {/* <img src="../../img/logo.png" className='imgss' alt="Logo" /> */}
                    <p style={{ textAlign: "center",fontSize:"22px",color:"#a3a3a3" }}>Loading....</p>
                    <div className="circle"></div>
                </div>

            </div>
        </div>
    );
};

export default Loader;