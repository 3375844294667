import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { BASE_URL } from './Config';
import Loader from './loader';
import { Helmet } from 'react-helmet-async';



function Product() {
   const currentUrl = window.location.href;
   // console.log(currentUrl)
   const [isLoading, setIsLoading] = useState(true);


   const vardata = currentUrl.split("/")
   // console.log(vardata)

   const var1 = vardata[3].split('?')
   // console.log(var1)
   const var2 = var1[1].split('&')
   
   const compStr = var2[0];
   const cateStr = var2[1];

   const compMatch = compStr.match(/\d+/);
   const cateMatch = cateStr.match(/\d+/);


   const compNumber = compMatch ? parseInt(compMatch[0], 10) : null;
   const cateNumber = cateMatch ? parseInt(cateMatch[0], 10) : null;

   const [products, setProducts] = useState([]);
   const [category, setCategory] = useState("");
   const [company, setCompany] = useState("");

   useEffect(() => {
      // console.log('Component mounted. compNumber:', compNumber, 'cateNumber:', cateNumber);

      axios.post(BASE_URL + 'Product.php', { Comp: compNumber, Cate: cateNumber })
         .then(response => {
            console.log(response.data);
            console.log(response.data.result)

            if (response.data.message === 'radha') {
               setProducts(response.data.result);
               setCategory(response.data.category)
               setCompany(response.data.company)
               console.log(response.data.result)
               setIsLoading(false)

            } else {
               console.log('No products found');
            }
         })
         
   }, [compNumber, cateNumber]);

   return (
      <div>

         {/* <h1>Product Page</h1>
         <p>Component: {compNumber}</p>
         <p>Category: {cateNumber}</p>  */}

         <Helmet>
            <title>{category} - {company} | RealWeight</title>
            <meta name="description" content="Realweight, established in 2015, is a trusted manufacturer and supplier of high-quality electronic weighing scales and scale spares. Our product range includes electronic table top scales, digital price computing scales, precision pocket scales, and more. We are committed to delivering durable, precise, and affordable weighing scale solutions. Choose Realweight for your weighing needs." />

            <meta property="og:title" content="RealWeight: Precision Weighing Scales Manufacturer & Supplier" />
            <meta property="og:site_name" content="RealWeight: Precision Weighing Scales Manufacturer & Supplier" />
            <meta property="og:url" content="https://realweight.in/" />
            <meta property="og:image" content="https://realweight.in/assets/img/logo.png" />
            <meta property="og:image:url" content="https://realweight.in/assets/img/logo.png" />
            <meta property="og:image:width" content="500" />
            <meta property="og:image:height" content="500" />
            <meta property="og:description"
               content="Realweight, established in 2015, is a trusted manufacturer and supplier of high-quality electronic weighing scales and scale spares. Our product range includes electronic table top scales, digital price computing scales, precision pocket scales, and more. We are committed to delivering durable, precise, and affordable weighing scale solutions. Choose Realweight for your weighing needs." />

         </Helmet>
         <main className="main__content_wrapper">

            <section class="breadcrumb__section breadcrumb__bg">
               <div class="container">
                  <div class="row row-cols-1">
                     <div class="col">
                        <div class="breadcrumb__content">
                           <h1 class="breadcrumb__content--title mb-10">{category}</h1>
                           <ul class="breadcrumb__content--menu d-flex">
                              <li class="breadcrumb__content--menu__items"><Link to="/">Home</Link></li>
                              <li class="breadcrumb__content--menu__items"><Link to="/">{company}</Link></li>
                              <li class="breadcrumb__content--menu__items"><span
                                 class="text__secondary">{category}</span></li>
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
            {isLoading ? (
               <Loader />
            ) : (<>
               <section className="shop__section section--padding">
                  <div className="container">
                     <div className="row">
                        <div className="col-12">
                           <div className="shop__product--wrapper">
                              <div className="tab_content">
                                 <div id="product_grid" className="tab_pane active show">
                                    <div className="product__section--inner product__grid--inner">
                                       <div className="row row-cols-xl-3 row-cols-lg-3 row-cols-md-3 row-cols-2 mb--n30">
                                          {/* Render the fetched products */}
                                          {products.map(product => (
                                             <div key={product.id} className="col custom-col-2 mb-30">
                                                <article className="product__card">
                                                   <div className="product__card--thumbnail">
                                                      <div className="swiper-wrapper">
                                                         <div className="swiper-slide">
                                                            <Link className="product__card--thumbnail__link display-block" to={`/product_details?${product.id}_${product.compName}-${product.cateName}-${product.productName}-(${product.modelNo})`}>
                                                               <img className="product__card--thumbnail__img product__primary--img display-block" src={`/assets/img/products/${product.img}`} alt="product-img" />
                                                               <img className="product__card--thumbnail__img product__secondary--img display-block" src={`/assets/img/products/${product.img}`} alt="product-img" />
                                                            </Link>
                                                         </div>
                                                      </div>
                                                   </div>
                                                   <div className="product__card--content text-center">
                                                      <span className="product__card--meta__tag">
                                                         {product.cateName}
                                                      </span>
                                                      <h3 className="product__card--title">
                                                         <Link to={`/product_details?${product.id}_${product.compName}-${product.cateName}-${product.productName}-(${product.modelNo})`}>
                                                            {product.productName}
                                                         </Link>

                                                      </h3>
                                                      <Link className="product__card--btn primary__btn" to={`/product_details?${product.id}_${product.compName}-${product.cateName}-${product.productName}-(${product.modelNo})`}>View More</Link>

                                                   </div>
                                                </article>
                                             </div>
                                          ))}
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </section>
            </>)}
         </main>
      </div>
   );
}

export default Product;
