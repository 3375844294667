


import React from 'react';

import { Link } from 'react-router-dom';
// import {} from '../API/Product.php';
// import React, { useEffect, useState } from "react"
import SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

import { Pagination, Autoplay } from 'swiper/modules';
import { Helmet } from 'react-helmet-async';

const Home = () => {
    SwiperCore.use([Pagination, Autoplay]);


    return (
        <div>

            <Helmet>
                <title>Welcome to RealWeight | RealWeight </title>
                <meta name="description" content="Realweight, established in 2015, is a trusted manufacturer and supplier of high-quality electronic weighing scales and scale spares. Our product range includes electronic table top scales, digital price computing scales, precision pocket scales, and more. We are committed to delivering durable, precise, and affordable weighing scale solutions. Choose Realweight for your weighing needs." />

                <meta property="og:title" content="RealWeight: Precision Weighing Scales Manufacturer & Supplier" />
                <meta property="og:site_name" content="RealWeight: Precision Weighing Scales Manufacturer & Supplier" />
                <meta property="og:url" content="https://realweight.in/" />
                <meta property="og:image" content="https://realweight.in/assets/img/logo.png" />
                <meta property="og:image:url" content="https://realweight.in/assets/img/logo.png" />
                <meta property="og:image:width" content="500" />
                <meta property="og:image:height" content="500" />
                <meta property="og:description"
                    content="Realweight, established in 2015, is a trusted manufacturer and supplier of high-quality electronic weighing scales and scale spares. Our product range includes electronic table top scales, digital price computing scales, precision pocket scales, and more. We are committed to delivering durable, precise, and affordable weighing scale solutions. Choose Realweight for your weighing needs." />

            </Helmet>
            <div>

                <main class="main__content_wrapper">

                    <Swiper class="hero__slider--section slider__section--bg2"
                        spaceBetween={30}
                        slidesPerView={1}
                        // pagination={{ clickable: true }}
                        autoplay={{
                            delay: 3000, // Specify the delay between slides in milliseconds (e.g., 2000ms = 2 seconds)
                        }}
                        loop={true} // Enable loop

                    >
                        <div class="hero__slider--inner style2 position__relative">
                            <div class="hero__slider--activation swiper">
                                <div class="hero__slider--wrapper swiper-wrapper">
                                    <SwiperSlide> <div class="swiper-slide ">
                                        <div class="hero__slider--items home2__slider--items__bg three">
                                            <div class="container">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="slider__content style2">
                                                            <h3 class="slider__content--maintitle h1"> Unlock your business's full <br />potential with Realweight</h3>
                                                            <p class="slider__content--desc style2 d-sm-2-none">Experience unmatched quality, reliability, and service that fuel your business's growth</p>
                                                            <div class="slider__content--footer style2 d-flex align-items-center">
                                                                <Link class="slider__content--btn primary__btn" style={{}} to="/Category">Shop Now</Link>
                                                                {/* <div class="bideo__play slider__play--bideo">
                                                                    <div class="bideo__play--icon" >
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 31 37">
                                                                            <path data-name="Polygon 1" d="M16.783,2.878a2,2,0,0,1,3.435,0l14.977,25.1A2,2,0,0,1,33.477,31H3.523a2,2,0,0,1-1.717-3.025Z" transform="translate(31) rotate(90)" fill="currentColor"></path>
                                                                        </svg>
                                                                        <span class="visually-hidden">Play</span>
                                                                    </div>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </SwiperSlide>
                                    <SwiperSlide><div class="swiper-slide ">
                                        <div class="hero__slider--items home2__slider--items__bg four">
                                            <div class="container">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="slider__content style2">
                                                        
                                                            <h3 class="slider__content--maintitle h2">We streamline your supply chain<br/> with innovative weighing scales.</h3>
                                                            <p class="slider__content--desc style2 d-sm-2-none">Experience precision and innovation with our cutting-edge weighing scales.</p>
                                                            <div class="slider__content--footer style2 d-flex align-items-center">
                                                                <Link class="slider__content--btn primary__btn" to="/Category">Shop Now</Link>
                                                                {/* <div class="bideo__play slider__play--bideo">
                                                                    <div class="bideo__play--icon" >
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 31 37">
                                                                            <path data-name="Polygon 1" d="M16.783,2.878a2,2,0,0,1,3.435,0l14.977,25.1A2,2,0,0,1,33.477,31H3.523a2,2,0,0,1-1.717-3.025Z" transform="translate(31) rotate(90)" fill="currentColor"></path>
                                                                        </svg>
                                                                        <span class="visually-hidden">Play</span>
                                                                    </div>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </SwiperSlide>
                                </div>
                            </div>
                        </div>
                        {/* <SwiperSlide>Slide 3</SwiperSlide> */}
                        {/* Add more slides as needed */}
                    </Swiper>
                    {/* <section class="hero__slider--section slider__section--bg2">
                    <div class="hero__slider--inner style2 position__relative">
                        <div class="hero__slider--activation swiper">
                            <div class="hero__slider--wrapper swiper-wrapper">

                                <div class="swiper-slide ">
                                    <div class="hero__slider--items home2__slider--items__bg three">
                                        <div class="container">
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="slider__content style2">
                                                        <h2 class="slider__content--maintitle h1">Measure your success<br /> with precision </h2>
                                                        <p class="slider__content--desc style2 d-sm-2-none">Experience precision and innovation with our cutting-edge weighing scales.</p>
                                                        <div class="slider__content--footer style2 d-flex align-items-center">
                                                            <Link class="slider__content--btn primary__btn" to="/Category">Shop Now</Link>
                                                            <div class="bideo__play slider__play--bideo">
                                                                <div class="bideo__play--icon" >
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 31 37">
                                                                        <path data-name="Polygon 1" d="M16.783,2.878a2,2,0,0,1,3.435,0l14.977,25.1A2,2,0,0,1,33.477,31H3.523a2,2,0,0,1-1.717-3.025Z" transform="translate(31) rotate(90)" fill="currentColor"></path>
                                                                    </svg>
                                                                    <span class="visually-hidden">Play</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper-slide ">
                                    <div class="hero__slider--items home2__slider--items__bg four">
                                        <div class="container">
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="slider__content style2">

                                                        <h2 class="slider__content--maintitle h1">Measure your success  <br />with precision</h2>
                                                        <p class="slider__content--desc style2 d-sm-2-none">Slide through excellence in measurement technology on our website.</p>
                                                        <div class="slider__content--footer style2 d-flex align-items-center">
                                                            <Link class="slider__content--btn primary__btn" to="/Category">Shop Now</Link>
                                                            <div class="bideo__play slider__play--bideo">
                                                                <div class="bideo__play--icon" >
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 31 37">
                                                                        <path data-name="Polygon 1" d="M16.783,2.878a2,2,0,0,1,3.435,0l14.977,25.1A2,2,0,0,1,33.477,31H3.523a2,2,0,0,1-1.717-3.025Z" transform="translate(31) rotate(90)" fill="currentColor"></path>
                                                                    </svg>
                                                                    <span class="visually-hidden">Play</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="slider__pagination style2 swiper-pagination"></div>
                        </div>
                    </div>
                </section>  */}



                    <section class="image__with--text__section section--padding">
                        <div class="container">
                            <div class="row row-cols-md-2 row-cols-1  align-items-center">
                                <div class="col">
                                    <div class="image__with--text__thumbnail">
                                        <img class="display-block" src="assets/img/weight/home.png" alt="drone-image" />
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="image__with--text__content">
                                        <h2 class="image__with--text__title mb-18">About Us</h2>
                                        <p class="image__with--text__desc mb-25" style={{ textAlign: 'justify' }} >Welcome to <b>Realweight</b>, India's premier weighing scale manufacturer. Established in 2015, we have embarked on an incredible journey, experiencing rapid growth and becoming the country's fastest-growing seller of weighing scales.
                                            <br />At <b>Realweight</b>, we take immense pride in the quality of our products. Each weighing scale we manufacture undergoes rigorous testing and adheres to the highest industry standards, ensuring accurate and reliable measurements for our customers. We understand the importance of precision when it comes to weighing, and our commitment to excellence has earned us the esteemed position of being the number one brand in the weighing scale industry.
                                        </p>

                                        <div class="image__with--text__content--footer d-flex">

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <img class="image__with--text__position--shape display-block" src="assets/img/other/image-with-text-section-shape.webp" alt="shape img" />
                    </section>
                    {/* <!-- End image with text section --> */}


                    {/* <!-- Start counterup section --> */}
                    <div class="counterup__section section--padding" id="funfactId">
                        <div class="container">
                            <div class="section__heading text-center mb-50">
                                <h2 class="section__heading--maintitle text__secondary mb-10">Why Choose Us ?</h2>
                                <p class="section__heading--desc">Beyond more stoic this along goodness this sed wow manatee mongos
                                    flusterd impressive man farcrud opened.</p>
                            </div>
                            <div class="counterup--inner">
                                <div class="row row-cols-md-4 row-cols-sm-3 row-cols-2 mb--n30">

                                    <div class="col mb-30">
                                        <div class="single__counterup">
                                            <div class="counterup__icon">
                                                <img src="assets/img/quality.png" width="60"
                                                    height="60" alt="Quality Icon" />
                                            </div>
                                            <h3 class="counterup__title">Qaulity</h3>
                                            <p style={{ textAlign: "justify;" }}>Streamlined functionality, empowering you to focus on value delivery while minimizing configuration and administration efforts.</p>
                                        </div>
                                    </div>
                                    <div class="col mb-30">
                                        <div class="single__counterup">
                                            <div class="counterup__icon">
                                                <img
                                                    width="60"
                                                    height="60"
                                                    src="assets/img/best-price.png"
                                                    alt="Best Price Icon" // Provide a description of the icon's purpose or content
                                                />
                                            </div>
                                            <h3 class="counterup__title">Prices</h3>
                                            <p style={{ textAlign: "justify;" }}>Our product you get in very affoedable and we guarantee you the best value of your money.</p>
                                        </div>
                                    </div>
                                    <div class="col mb-30">
                                        <div class="single__counterup">
                                            <div class="counterup__icon">

                                                <img src="assets/img/order.png" id="_1850963" data-name="1850963" width="60" height="60" />

                                            </div>
                                            <h3 class="counterup__title">Latest Items</h3>
                                            <p style={{ textAlign: "justify;" }}>If you are also a person with a specific taste then you are in right place.We provide all kind of latest product for you.</p>
                                        </div>
                                    </div>
                                    <div class="col mb-30">
                                        <div class="single__counterup">
                                            <div class="counterup__icon">
                                                <img width="60" height="60" src="assets/img/customer-service.png" />
                                            </div>
                                            <h3 class="counterup__title">Customer Support</h3>
                                            <p style={{ textAlign: "justify;" }}>We have dealers and partner across the india.We can service your scale within 24 hours anywhere in india.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- End counterup section --> */}


                    <div class="instagram__section" style={{ margin: "20px", padding: "20px", backgroundImage: `url("assets/img/other/image-with-text-section-shape.webp")` }}>
                        <div class="container p-2">
                            <h2 style={{ textAlign: "center", margin: "10px" }} class="text__secondary">Our Certificates</h2>
                            <div class="" style={{ padding: "5px" }}>
                                <div class="row">
                                    <div class="col-md-3">
                                        <div class="client" style={{ paddingBottom: "10px" }}>
                                            <img class="instagram__thumbnail--img display-block" src="assets/img/certi/iso.jpg" alt="instagram-img" style={{ width: "100%" }} />
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="client" style={{ paddingBottom: "10px" }}>
                                            <img class="instagram__thumbnail--img display-block" src="assets/img/certi/msme.jpg" alt="instagram-img" style={{ width: "100%" }} />
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="client" style={{ paddingBottom: "10px" }}>
                                            <img class="instagram__thumbnail--img display-block" src="assets/img/certi/iaf.jpg" alt="instagram-img" style={{ width: "100%" }} />
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="client" style={{ paddingBottom: "10px" }}>
                                            <img class="instagram__thumbnail--img display-block" src="assets/img/certi/made.jpg" alt="instagram-img" style={{ width: "100%", height: "100%" }} />
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="client" style={{ paddingBottom: "10px" }}>
                                            <img class="instagram__thumbnail--img display-block" src="assets/img/certi/zed.jpg" alt="instagram-img" style={{ width: "100%" }} />
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="client" style={{ paddingBottom: "10px" }}>
                                            <img class="instagram__thumbnail--img display-block" src="assets/img/certi/lms.jpg" alt="instagram-img" style={{ width: "100%" }} />
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="client" style={{ paddingBottom: "10px" }}>
                                            <img class="instagram__thumbnail--img display-block" src="assets/img/certi/dac.jpg" alt="instagram-img" style={{ width: "100%" }} />
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="client" style={{ paddingBottom: "10px" }}>
                                            <img class="instagram__thumbnail--img display-block" src="assets/img/certi/gem.jpg" alt="instagram-img" style={{ width: "100%" }} />
                                        </div>
                                    </div>
                                </div>
                                <div class="swiper__nav--btn swiper-button-next"></div>
                                <div class="swiper__nav--btn swiper-button-prev"></div>
                            </div>
                        </div>
                    </div>





                </main>
            </div>
        </div >
    )
}

export default Home
